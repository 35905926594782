<template>
    <div>
        <div class="topheader">
            <!-- 引入的头部组件（传入页面数据 -->
            <headerbox
                    :list="list"
                    @changenar="changenar"
                    :pagenum="primary"
                    :chinum="secondary"
            />
        </div>

  <div class="contentIn">
    <div class="user findIn">
      <div class="title">
        <div class="titleW">重置密码</div>
        <div class="progressModule  progressModule2">
          <p class="infoTips">
            <i class="el-icon el-icon-warning-outline"></i>密码格式为由大写字母、小写字母、数字、特殊字符(除&lt;&gt;)四种组成长度为8-32的字符串</p>
            <div class="layui-form-item">
                  <div class="layui-input-block">
                      <el-input
                              class="input-styl"
                              v-model="password"
                              maxlength="32"
                              minlength="8"
                              placeholder="请输入原密码"
                              show-word-limit
                              type="password"
                      />
                    <p class="error_msg"></p>
                </div>
            </div>

          <div class="layui-form-item">
            <div class="layui-input-block">
              <el-input
                      class="input-styl"
                      v-model="passwordOld"
                      maxlength="32"
                      minlength="8"
                      placeholder="请设置新密码"
                      @keyup.native="keyUpChangeNew()"
                      type="password"
              />
              <p class="error_msg" id="pwd1error_msg">{{error1}}</p></div>
          </div>
          <div class="layui-form-item">
            <div class="layui-input-block">
              <el-input
                      class="input-styl"
                      v-model="passwordNew"
                      maxlength="32"
                      minlength="8"
                      placeholder="请重新输入新密码"
                      @keyup.native="keyUpChange()"
                      type="password"
              /><p class="error_msg" id="pwd2error_msg">{{error2}}</p></div>
          </div>
          <div class="layui-form-item">
            <div class="layui-input-block">
              <el-button class="btn-sure" type="primary" @click="sure">确定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
</template>

<script>
    // //  引入的头部组件
    import headerbox from "@/components/header/headerbox";
    // //  引入的尾部组件
    // import footerbox from "@/components/footer/footerbox";
    // //  引入的导航组件
    // import leftab from "@/components/all/leftab";
    // //  引入的页面组件
    // import workgroup from "../../components/page/work/workgroup";

    export default {
        data() {
            return {
              password:'',
                list: [], //导航栏数组
                primary: 1, //一级菜单的选中样式
                secondary: 0, //二级菜单的选中样式
                istrue: true, //刷新导航栏数据
                socllist: [0, 300],
                narlist:[],
                passwordNew:'',
                passwordOld:'',
                error1:'',
                error2:'',
                info:''
            };
        },
        created() {
            this.secondary = this.$route.query.chinum;
            this.info = this.until.cacheSync("get", "pigx-userInfo");
        },
        mounted() {
            // 进入页面加载导航栏数据
            // this.getlist();
            // console.log(document.getElementById('cnzz_stat_icon_1280450777'));
            document.getElementById('cnzz_stat_icon_1280450777').style.display = 'none';
        },
        components: {
            headerbox, //引入的头部组件
        //     footerbox, //引入的尾部组件
        //     workgroup, //引入的页面组件
        //     leftab, //引入的导航组件
        },
        methods: {
            keyUpChangeNew(){
                let reg = /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*<)(?!.*>).{8,32}$/;
                if(!reg.test(this.passwordOld)){
                    this.error1 = '密码格式为由大写字母、小写字母、数字、特殊字符(除<>)四种组成长度为8-32的字符串';
                    return;
                }else {
                    this.error1="";
                }
            },
            keyUpChange(){
                let reg = /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*<)(?!.*>).{8,32}$/;
                if(!reg.test(this.passwordNew)){
                    this.error2 = '密码格式为由大写字母、小写字母、数字、特殊字符(除<>)四种组成长度为8-32的字符串';
                    return;
                }else {
                    this.error2="";
                }
            },
            sure(){
                let reg = /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*<)(?!.*>).{8,32}$/;
                if(this.passwordOld == ''){
                    this.error1 = '密码不能为空';
                    return;
                }
                if(this.passwordNew == ''){
                    this.error2 = '密码不能为空';
                    return;
                }

                if(!reg.test(this.passwordOld)){
                    this.error1 = '密码格式为由大写字母、小写字母、数字、特殊字符(除<>)四种组成长度为8-32的字符串';
                    return;
                }
                if(!reg.test(this.passwordNew)){
                    this.error2 = '密码格式为由大写字母、小写字母、数字、特殊字符(除<>)四种组成长度为8-32的字符串';
                    return;
                }
                if(this.passwordOld != this.passwordNew){
                    this.error2 = '两次密码不一致';
                    return;
                }
                if(this.passwordNew == this.password){
                    this.error1 = '原密码和新密码不能一样！';
                    return;
                }

                setTimeout(() => {
                  this.$api.changePassword({
                    "username":this.info.content.username,//账号
                    "password":this.password,//初始密码
                    "newpassword1":this.passwordOld,//新密码1
                    "newpassword2":this.passwordNew//新密码2
                  }).then((res) => {
                    // console.log(res);
                    if(res.code == 1) {
                        this.$message.error(res.msg);
                    }else if(res.data){
                        window.sessionStorage.clear()
                        window.localStorage.clear()
                        // 点击登录跳转到后台的登录页面
                        this.until.jump.call(this, "/uiadmin", "href");
                    }
                  });
                }, 100);

            },
            // 进入页面加载导航栏数据
            getlist() {
                // 发起请求
                this.$api.getNavigation({}).then((res) => {
                    // 把数组放到data中
                    this.list = res.data;
                    this.narlist=res.data[5].children
                });
            },
            // 刷新当前页面
            refresh() {
                this.istrue = false;
                this.$nextTick(() => {
                    this.istrue = true;
                });
            },
            // 点击导航传出当前选中菜单
            changenar(e) {
                this.primary = e.pagenum; //一级菜单的选中样式
                if (this.primary != 1||this.secondary != e.chinum) {
                    this.secondary = e.chinum; //二级菜单的选中样式
                    this.until.jump.call(this, this.list[this.primary].path, "push", {
                        pagenum: this.primary,
                        chinum: this.secondary,
                    });
                    this.refresh();
                }
            },
        },
    };
</script>

<style scoped lang="less">
  .contentIn {
    margin: 3rem auto 1rem;
    /* min-height: 100%; */
  }
  .mainContent {
    min-height: 100%!important;
    overflow: hidden;
  }
  .user{
    margin: 50px auto 0;
    background: #fff;
    padding: 30px;
    width: 1000px;
    box-shadow: 2px 2px 8px 2px #ededed;
    max-width: 100%;
    overflow: hidden;
  }
  .findIn{
    width: 50rem;
    height: 30rem;
    margin: 0 auto;
    border: 1px #d2d2d2 solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 0 0 0;
  }
  .title {
    background: none;
    text-align: center;
    padding-left: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .titleW {
    font-size: 2.5rem;
    border-bottom: 0.1rem #dcdcdc solid;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    margin-bottom: 2rem;
    line-height: 3rem;
    color: #00b1e4;
  }
  .progressModule {
    width: 42rem;
    margin: 0 auto;
  }
  .infoTips{
    height: 2rem;
    line-height: 2rem;
    color: #999999;
    padding-bottom: 1rem;
  }
  .error_msg{
    height: 2rem;
    line-height: 2rem;
    text-align: left;
    color: #f00;
  }
  @media screen and (max-width: 1023px) {
      .infoTips{
          height: 3rem;
      }
      .findIn {
          width: 23rem;
      }
      .progressModule{
          width: 20rem;
          margin: 0 auto;
      }
  }
</style>
<style>
  .input-styl input{
    height: 2.5rem!important;
    /*margin-top: 0.5rem;*/
  }
  .btn-sure{
    width: 100%;
    height: 2.5rem;
    /*margin: 1.5rem auto;*/
  }
  html{
    background: #f6f6f6;
  }
</style>
