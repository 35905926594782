<template>
  <!-- 首页代码 -->
  <div class="mainpage">
    <!-- 封装的头部和尾部的组件 -->
    <pagebox
      v-if="istrue"
      @changenar="changenar"
      :pagenum="primary"
      :chinum="secondary"
    >
      <div id="homebox">
        <div id="pagebox">
            <div v-if="primary == defaultPrimary.workdetail">
              <!-- 封装的工作组详情 -->
              <workdetail :chinum="secondary" :id="workid" />
            </div>
            <!-- 封装的性能基准详情 -->
            <div v-if="primary == defaultPrimary.markdetail">
              <markdetail :chinum="secondary" :id="workid" />
            </div>
            <!-- 封装的基准榜单详情 -->
            <div v-if="primary == defaultPrimary.billdetail">
              <billdetail v-if="workid == 1" :chinum="secondary" :id="workid"/>
              <billdetail2 v-else :chinum="secondary" :id="workid"/>
            </div>
             <!-- 封装的基准标准详情 -->
            <div v-if="primary == defaultPrimary.pipdetail">
              <pipdetail :chinum="secondary" :id="workid" />
            </div>
             <!-- 封装的工具下载详情 -->
            <div v-if="primary == defaultPrimary.tooldetail">
              <tooldetail :chinum="secondary" :id="workid" />
            </div>
        </div>
      </div>
    </pagebox>
  </div>
</template>

<script>
import pagebox from "../../components/page/pagebox"; //封装的头部和尾部的组件
import workdetail from "../../components/page/work/workdetail"; //封装的工作组详情
import markdetail from "../../components/page/property/markdetail"; //封装的性能基准详情
import billdetail from "../../components/page/billboard/billdetail"; //封装的基准榜单详情
import billdetail2 from "../../components/page/billboard/billdetail2"; //封装的基准榜单详情
import pipdetail from "../../components/page/criterion/pipdetail"; //封装的基准标准详情
import tooldetail from "../../components/page/tooldown/tooldetail"; //封装的工具下载详情


export default {
  data() {
    return {
      primary: 1, //一级菜单的选中样式
      secondary: 1, //二级菜单的选中样式
      istrue: true, //刷新页面
      workid: "", //当前id

      defaultPrimary: {
        markdetail: 1,
        billdetail: 2,
        pipdetail: 3,
        tooldetail: 4,
        // 这个页面删掉了，给个-1占位
        workdetail: -1,
      }
    };
  },
  components: {
    pagebox, //全局引用页面样式
    workdetail, //封装的工作组详情
    markdetail, //封装的性能基准详情
    billdetail, //封装的基准榜单详情
    billdetail2, //原来的基准榜单详情
    pipdetail,//封装的基准标准详情
    tooldetail,//封装的工具下载详情
  },
  //   接受页面的参数
  created() {
    this.workid = this.$route.query.id;
    this.primary = this.$route.query.pagenum;
    this.secondary = this.$route.query.chinum;
        document.documentElement.scrollTop = 0;
  },
  methods: {
    // 接受传输过来的值进行页面的切换
    changenar(e) {
      this.primary = e.pagenum; //一级菜单的选中样式
      this.secondary = e.chinum; //二级菜单的选中样式
      console.log('primary', this.primary, this.secondary)
      //  进行路由跳转
      var list = this.until.cacheSync("get", "navigation").data;
      this.until.jump.call(this, list[this.primary].path, "push", {
        pagenum: this.primary,
        chinum: this.secondary,
      });
    },
  },
};
</script>

<style scoped lang="less">
// 当前页面最大的盒子（配置背景色
.mainpage {
  min-height: 100vh;
  width: 100%;
  background-color: #e2e2e217;
}
.orderbox {
  align-items: initial;
  margin-top: 1.25rem;
}
.lefttab {
  width: 15rem;
  margin-right: 0.625rem;
}
.rightbox {
  flex: 1;
}
#homebox {
  background-image: url('../../assets/homeBg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 75vh;
}
</style>
