<template>
  <div class="billdetail-box">
    <div class="box" v-if="istrue">
      <!-- <crumbs :list="list" :pagenum="3" :chinum="secondary" /> -->
      <div class="centext" v-if="chinum != 2">
            <div v-if="(billList.length > 0)" class="billListBoxContainer">
              <div class="billListBox">
              <!-- 榜单： -->
                <span 
                  v-for="(item, i) of billList" 
                  :class="{'billListTitle':true, 'billListHighLight': keyHandler(item.id) == activeBill}" 
                  :key="i"
                  @click="jumpHandler(item.id)"
                >
                  {{item.name}}
                </span>
              </div>
            </div>
          <!-- </el-col> -->
          <el-col :span="24">
            <div class="boardContainer">
              <!-- <div class="billHead">CPUBench</div> -->
              <div class="billTitle">{{ billName() }}</div>
              <div 
                v-for="(value, key, i) in pages" 
                :key="i" 
                :id="('bill_detail_' + key)"
              >
                  <div v-show="key == activeBill"  class="dropDown">
                    <el-dropdown
                        trigger="click"
                        :hide-on-click="false"
                        placement="bottom-start"
                    >
                        <span class="el-dropdown-link">
                            <!-- 筛选表头<i class="el-icon-arrow-down el-icon--right"></i> -->
                          <i class="el-icon-setting"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <div class="dropDownItem">
                                <el-checkbox 
                                    v-show="allTableData[key] && allTableData[key].length != 0 "
                                    :indeterminate="isIndeterminate[key]"
                                    v-model="checkAll[key]"
                                    @change="(val) => handleCheckAllChange(val, key)"
                                >全选</el-checkbox>
                            </div>
                            <el-checkbox-group
                                v-model="checkedOption[key]"
                                @change="(val) => handleCheckedTableChange(val, key)"
                            >
                                <div class="dropDownList">
                                    <div
                                        v-for="(item, index) in tableOption[key]"
                                        :key="index"
                                        class="dropDownItem"
                                    >
                                      <el-checkbox
                                          :label="item"
                                          :disabled="item === '链接'"
                                      >
                                        {{columnRender[item] ? columnRender[item] : item}}
                                      </el-checkbox>
                                    </div>
                                </div>
                            </el-checkbox-group>
                        </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div 
                    v-show="key == activeBill" 
                    v-loading="csvLoading"
                    element-loading-spinner="el-icon-loading"
                    class="tableBox"
                  >
                    
                    <div class="summary">{{ drtail[key].summary }}</div>
                    <div class="ql-editor" v-html="htmlBillContent[key]"></div>
                    
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-table
                            :data="tableData[key]"
                            style="width: 600%"
                            :header-cell-style="{'background': '#4472C4', 'color': 'white'}"
                            type="index"
                            border
                            @sort-change="({ prop, order }) => tableSortChange({ prop, order }, key)"
                            height="530px"
                            :ref="`elTable${key}`"
                            :row-class-name="tableRowClassName"
                            >
                            <el-table-column
                                v-for="(v, i) in checkedOption[key]"
                                :key="i"
                                :prop="v"
                                :label="columnRender[v] ? columnRender[v] : v"
                                :align="'center'"
                                :min-width="colWidth[key][i]"
                                :sortable="(dowloadBtn[key] && i == checkedOption[key].length - 1) ? false : 'custom'"
                                :fixed="(i == checkedOption[key].length - 1) ? 'right' : false"                                
                            >
                            <template slot-scope="scope">
                              <div
                                v-if="i == checkedOption[key].length - 1" 
                              >
                                <el-button 
                                  @click="downLoad('ini', scope.row)" 
                                  type="text" 
                                  size="small"
                                  style="font-size: 1rem;"
                                  >配置文件
                                </el-button>
                                <el-button 
                                  @click="downLoad('pdf', scope.row)" 
                                  type="text" 
                                  size="small"
                                  style="font-size: 1rem;"
                                  >PDF
                                </el-button>
                              </div>
                              <span v-else-if="v === 'CPU型号'">
                                <span
                                  style="color: #3335FA; font-weight: bolder;"
                                >{{scope.row[v]}}</span>
                              </span>
                              <span v-else-if="v === 'Typical分数'">
                                <span
                                  style="color: #3335FA; font-weight: bolder;"
                                >{{scope.row[v] ? scope.row[v] : '--'}}</span>
                              </span>
                              <span v-else-if="v === 'deleted'">
                                <span
                                  style="color: black;"
                                >{{scope.row[v] ? '已删除' : '未删除'}}</span>
                              </span>
                              <span 
                                v-else-if="v === 'status'" 
                                style="color: black;"
                              >
                                <span>{{allStatus[scope.row[v]]}}</span>
                              </span>
                              <span v-else
                                style="color: black;"
                              >
                                <b v-if="boldCol[key].indexOf(v) > -1 && colorCol[key].indexOf(v) > -1 && color[key]" :style="'color:'+color[key]">{{lengthSub(scope.row[v])}}</b>
                                <b v-else-if="boldCol[key].indexOf(v) > -1">{{lengthSub(scope.row[v])}}</b>
                                <span v-else-if="colorCol[key].indexOf(v) > -1 && color[key]" :style="'color:'+color[key]">{{lengthSub(scope.row[v])}}</span>
                                <span v-else>{{lengthSub(scope.row[v])}}</span>
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>
                        <el-pagination
                            @size-change="(val) => sizeChangeHandle(val, key)"
                            @current-change="(val) => currentChangeHandle(val, key)"
                            :current-page="pages[key].current"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pages[key].size"
                            :total="pages[key].total"
                            layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                      </el-col>
                    </el-row>
                    <div class="ql-editor" v-html="htmltitle[key]"></div>
                </div>
              </div>
            </div>
          </el-col>
        <!-- </el-row> -->
      </div>
      <div class="centext" v-if="chinum == 2">
        <div class="title text">
          <span>{{ billist.name }}</span>
          <div class="time">
            <span>开始日期 ：{{ billist.startTime }}</span>   
            <span style="opacity: 0;">111</span>
            <span>截止日期 ：{{billist.finishTime}}</span></div>
        </div>
        <div class="flexcent mode">
          <div
            class="linkpath"
            @click="tolink(item.id, index)"
            v-for="(item, index) in detaillist"
            :key="index"
          >
            [{{ item.name }}]
          </div>
        </div>
        <div class="bord summary">{{ billist.summary }}</div>
        <div class="title text">测试要求</div>
        <div class="ql-editor" v-html="billist.demandHtml"></div>
        <div class="title text">报名表</div>
        <div class="ql-editor" v-html="billist.formHtml"></div>
        <div class="flexcent mode">
          <div>报名方式 :</div>
          <img src="../../../assets/qq.png" />
          <img src="../../../assets/wx.png" />
          <img src="../../../assets/yx.png" />
        </div>
      </div>
    </div>
    <div class="backtop" style="right: 40px; bottom: 40px;" @click="returnTop"><i class="el-icon-caret-top"></i></div>
  </div>
</template>

<script>
// import crumbs from "../../all/crumbs"; //面包屑
// import cpuBench from "./cpuBench.vue";
export default {
  name: "billdetail", //基准榜单
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    id: {
      typeof: [String],
    }
  },
  components: {
    // crumbs,
    // cpuBench,
  },
  data() {
    return {
      secondary: 1, //二级菜单的选中样式
      list: ["首页", "基准榜单", ""],
      narlist: [], //当前导航栏数组
      drtail: {}, //当前内容
      htmltitle: {}, //当前富文本 结果描述
      htmlBillContent: {},  // 富文本  榜单描述
      drtailReport:[], // 测试报告
      billist: {}, //榜单数据
      detaillist: [], //
      istrue:true,

      pages: {},
      dowloadBtn: {},
      checkAll: {},
      isIndeterminate: {},
      checkedOption: {},
      tableOption: {},
      allTableData: {},
      tableData: {},
      showCol: {},
      boldCol: {},
      colorCol: {},
      color: {},
      col60:['序号'],
      col70:['Job数',],
      col120:['Typical分数', 'Max频率', '基频', 'Extreme分数', '测试日期',  '测试套件', '链接',  "测试版本"],
      col180:['计算机型号', '厂商', 'CPU型号', '上传时间', '操作系统', 'L1缓存', 'L2缓存', 'L3缓存'],
      col220:['编译器'],
      col260:[ '内存', '测试者'],
      colWidth: {},
      billList: [], // 榜单列表

      activeBill: '',
      csvLoading: false,

      showCol_new:[
        "计算机型号",
        "厂商",
        "CPU型号",
        "测试套件",
        "Job数",
        "基频",
        "Typical分数",
        "Extreme分数",
        "测试版本",
        "上传时间",
        // "内存",
        // "status",
        // "其他软件信息",
        // "id",
        "上传者",
        "编译器",
        "Max频率",
        "L1缓存",
        "L2缓存",
        "L3缓存",
        // "deleted",
        // "fileId",
        // "filename",
        // "id",
        // "jsonPath",
        // "pdfPath",
        // "status",
        // "typeCode",
        "其他硬件信息",
        "其他软件信息",
        "内存",
        "大页内存",
        "指针大小",
        "操作系统",
        "文件系统",
        "测试项",
        "硬盘",
        "透明大页",
        "测试日期",
        "测试者",
        "链接",
      ],
      showCol_new_init: [
        "计算机型号",
        "厂商",
        "CPU型号",
        "测试套件",
        "Job数",
        "基频",
        "Typical分数",
        "Extreme分数",
        // "测试版本",
        // "上传时间",
        // "内存",
        // "status",
        // "其他软件信息",
        "链接"
      ],
      columnRender: {
        'status': '状态',
        'deleted': '是否删除'
      },
      allStatus: {
        0: '新上传',
        1: '审批发布',
        2: '无效发布',
        3: '驳回',
        4: '已删除',  
      },
    };
  },
  created() {
    console.log('this.secondary', this.secondary)
    if (this.secondary != 2) {
      // 获取榜单列表
      this.getBillboardList()
      // this.getdetail();
    } 
    this.secondary = this.chinum; //同步一级菜单
    this.getnarlist()
  },
  mounted() {
    if (this.secondary == 2) { 
      this.getthidlist();
      this.getlist();
    }
  },
  methods: {
    tableRowClassName(data) {
      let {rowIndex} = data
      // console.log('row', row, rowIndex)
      let tag = Number(rowIndex) % 2
      // console.log('tag', tag)
      return `rowbg_${tag}`
    },
    // 进入页面加载导航栏数据
    getnarlist() {
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.narlist = res.data[3].children;
        this.list[2] = this.narlist[this.secondary].name;
        this.istrue=false
        this.$nextTick(()=>{
          this.istrue=true
        })
      });
    },
    tolink(id, index) {
      this.list[3] = this.billist.name;
      var obj = {
        id: id,
        title: "",
        list: this.list,
        chinum: this.secondary,
        pagenum: 3,
      };
      if (index == 2) {
        obj.title = "基准工具";
      } else if (index == 1) {
        obj.title = "标准明细";
      } else if (index == 0) {
        obj.title = "性能基准";
      }
      //  进行路由跳转
      this.until.jump.call(this, "/lastdetail", "push", obj);
    },
    // 获取榜单列表
    getBillboardList(){
      this.$api
          .cpuBenchBillList({
            // current: 1, //当前页
            // size: 20, //页码数量
            // title: "", //标题
            // status: 1, //状态
            // // cateroyCode: num, //活动分类
            // name: "", //榜单名称
            // billboardId: this.id // 大榜单id
          })
          .then((res) => {
            if (res.code === 0) {
              let billData = res.data || []
              billData = billData.map((item) => {
                let {
                  id, 
                  name,
                  typeCode,
                  createdTime,
                } = item
                return {
                  billboardId: id,
                  id: typeCode,
                  name,
                  createdTime,
                }
              })
              this.billList = billData
              this.getdetail()
            }
            

          });
    },
    getdetail() { 
      this.init()
      // console.log('this.page', this.pages)
      for(let item of this.billList){
        // console.log('item.id', item.id)
        let key = this.keyHandler(item.id)
        this.drtail[key] = item
        this.getCsvPage(key, 1, 20)
      }
    },
    // 初始化变量
    init(){
      this.activeBill = this.keyHandler(this.billList[0].id)
      
      for(let item of this.billList){
        let id = this.keyHandler(item.id)

        this.drtail[id] = {}
        this.htmltitle[id] = ""
        this.htmlBillContent[id] = ""
        this.pages[id] = {
          current: 1,
          size: 20,
          total: 0
        }
        this.dowloadBtn[id] = false
        this.checkAll[id] = []
        this.isIndeterminate[id] = false
        this.checkedOption[id] = []
        this.tableOption[id] = []
        this.allTableData[id] = []
        this.tableData[id] = []
        this.showCol[id] = []
        this.boldCol[id] = []
        this.colorCol[id] = []
        this.color[id] = ''
        this.colWidth[id] = []
      }  
    },
    keyHandler(id){
      return id + "_id"
    },
    filterPages(pages) {
      let p = {}
      let key = this.activeBill
      if (key !== '') {
        p[key] = pages[key]
      }
      console.log('p', key, p)
      return p
    },
    unKeyHandler(key){
      return key.split('_')[0]
    },
    getCsvPage(id, current, size) {
      // console.log('id', id, current, size)
      if (this.pages[id].order === undefined) {
        this.getCsvPageNew(this.unKeyHandler(id), current, size)
      } else {
        let {prop, order} = this.pages[id].order
        let b = order == 'ascending' ? true : false
        this.getCsvPageNew(this.unKeyHandler(id), current, size, prop, b)
      } 
    },
    // 分页获取csv数据列表
    getCsvPageNew(id, current, size, orders, asc){
      this.csvLoading = true

      let key = this.keyHandler(id)
      this.tableData[key] = [];
      this.allTableData[key] = [];

      // status=1为审核后发布
      // status=2为无效发布
      let params = id + '/page?current=' + current + '&size=' + size;
      if(orders){
        if(asc){
          params += '&ascs=' + orders
        }else{
          params += '&descs=' + orders
        }
      }

      // this.$api.getcppbtoolresult(params).then((res) => {
      this.$api.cpuBenchList(params).then((res) => {
        let record = res?.data?.records || []
        if (record.length == 0) {
          this.csvLoading = false
          return``
        }

        for(let item of res.data.records){
          // console.log('item', item)
          // 插入一列操作行
          // item['下载报告'] = ''
          let kt = Object.keys(item)[Object.keys(item).length - 1]
          if(item[kt].indexOf('/admin/sys-file') > -1){
            item[kt] = window.location.protocol + '//' + window.location.host + item[kt]
          }
        }
        this.allTableData[key] = res.data.records;
        this.tableData[key] = res.data.records;
        // console.log('this.tableData', this.tableData)
        // let titles = Object.keys(res.data.records[0]);
        
        this.tableOption[key] = this.showCol_new
        // console.log('this.tableOption[key]', this.showCol_new, this.tableOption[key])
        // fixme 这里判断需要展示的列，但是showCol是后台配置，需要加接口
        // this.checkedOption[key] = this.checkedOption[key] && this.checkedOption[key].length > 0 ? this.checkedOption[key] : (this.showCol[key] ? this.showCol[key] : titles);
        this.checkedOption[key] = this.checkedOption[key] && this.checkedOption[key].length > 0 ? this.checkedOption[key] : this.showCol_new_init
        this.colWidthHandler(key)
        this.checkLastColIsFile(key)
        // console.log('this.tableOption', this.tableOption)

        this.pages[key].total = res.data.total
        this.$forceUpdate()
        this.fiexdColStyle()
      });
    },
    // 每页数
    sizeChangeHandle (val, id) {
      this.pages[id].size = val
      this.pages[id].current = 1
      this.getCsvPage(id, this.pages[id].current, this.pages[id].size)
    },
    // 当前页
    currentChangeHandle (val, id) {
      this.pages[id].current = val
      console.log('order props', this.pages[id].order)
      this.getCsvPage(id, val, this.pages[id].size)
      // if (this.pages[id].order === undefined) {
      //   this.getCsvPageNew(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size)
      // } else {
      //   let {prop, order} = this.pages[id].order
      //   let b = order == 'ascending' ? true : false
      //   this.getCsvPageNew(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size, prop, b)
      // }      
    },
    // 表格排序触发
    tableSortChange({ prop, order }, id){
      this.pages[id].current = 1
      this.pages[id].order = {
        prop,
        order,
      }
      this.getCsvPage(id, this.pages[id].current, this.pages[id].size)

      // if(order == null){
      //   this.getCsvPageNew(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size)
      // }else{
      //   let b = order == 'ascending' ? true : false
      //   this.getCsvPageNew(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size, prop, b)
      // }

    },
    // 获取富文本信息 结果描述
    gethtml(id, pid) {
      this.$api.getWorkHtml(id).then((res) => {
        this.htmltitle[pid] = res.data.content;
      });
    },
    // 榜单描述  富文本
    getHtmlBill(id, pid){
      this.$api.getWorkHtml(id).then((res) => {
        this.htmlBillContent[pid] = res.data.content;
      });
      
    },
    // 获取当前数据
    getthidlist() {
      this.$api.getSiugnupList({}).then((res) => {
        res.data.forEach((item) => {
          if (this.id == item.standardId) {
            this.billist = item;
          }
        });
      });
    },
    // 获取当前数据
    getlist() {
      this.$api.postdetailData({ specId: this.id }).then((res) => {
        var obj1 = {
          id: res.data.spec.id,
          name: res.data.spec.title,
        };
        var obj2 = {
          id: res.data.standard.id,
          name: res.data.standard.name,
        };
        var obj3 = {
          id: res.data.tool.id,
          name: res.data.tool.name,
        };
        this.detaillist.push(obj1, obj2, obj3);
      });
    },

    // 字符串长度截取
    lengthSub(txt){
      return txt ? txt : '--'
      // return txt == null || txt == '' || txt.length < 30 ? txt : txt.substr(0, 30) + '...';
    },
    // 列宽度
    getColWidth(c) {
      if(this.col60.indexOf(c) > -1){
        return '60px';
      }
      else if(this.col70.indexOf(c) > -1){
        return '70px';
      }
      else if(this.col120.indexOf(c) > -1){
        return '120px';
      }
      else if(this.col180.indexOf(c) > -1){
        return '180px';
      }
      else if(this.col220.indexOf(c) > -1){
        return '230px';
      }
      else if(this.col260.indexOf(c) > -1){
        return '270px';
      }
      else{
        return '120px';
      }
    },
    colWidthHandler(id){
      this.colWidth[id] = []
      for(let k of this.checkedOption[id]){
        let w = this.getColWidth(k)
        this.colWidth[id].push(w)
      }
    },
    // 检查最后一列是不是文件下载
    checkLastColIsFile(id){
      this.dowloadBtn[id] = false
      for(let item of this.tableData[id]){
          let key = this.checkedOption[id][this.checkedOption[id].length - 1]

          // console.log('item', item, key, id)
          if (typeof item[key] !== 'string') {
            continue
          }
          if(item[key].indexOf('/admin/sys-file') > -1){
            this.dowloadBtn[id] = true
          }
      }
      this.$forceUpdate()
    },
    // 固定下载列 样式问题修复
    fiexdColStyle(){
      // return
      // let self = this
      this.reRender(this.activeBill)
      this.$nextTick(() => {
        setTimeout(function(){
          // 固定下载列 样式问题修复
          let doms = document.getElementsByClassName("el-table__fixed-body-wrapper");
          // console.log('doms', doms)
          if(doms != null && doms.length > 0){
            for(let dom of doms){
              dom.style.top = '47px'
            }
            // dom.setAttribute('style', "top: 38px;")
            // 把loading结束的标记放在这里，以免看到样式变动
          }
        }, 60)
      })
      this.csvLoading = false
    },

    downloadFile(url){
      window.open(url, '_blank', 'fullscreen=yes')
      // let a = document.createElement("a");
      // a.href = url;
      // a.target = '_blank';
      // a.click();
      // window.location.href = url
    },
    downLoad(type, data) {
      // let params = `4/download/${type}`
      let url = data['pdfPath']
      if (type === 'ini') {
        url = data['confPath']
      }
      console.log('type', type, data, url)
      window.open(url, '_blank')
    },

    billName() {
      if (this.activeBill.length < 3) {
        return ''
      }
      let list = this.billList
      let active = this.activeBill.slice(0, -3)
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.id === active) {
          return `${element.name}测试套件数据列表`
        }
      }
    },
    // 锚点定位
    jumpHandler(id){
      // let kk = 'bill_detail_' + this.keyHandler(id)
      // document.querySelector('#'+kk).scrollIntoView({
      //   'behavior' : 'smooth'
      // })
      //  解决切换表格之后固定列不见了的问题
      this.activeBill = this.keyHandler(id)
      console.log('id', id, this.activeBill)
      let tableId = `elTable${this.activeBill}`
      // console.log('this.', this.$refs[tableId][0], tableId)
      this.$nextTick(() => {
          this.$refs[tableId][0].doLayout();
      })
      // console.log('active', this.activeBill)

      // this.getCsvPageNew(id, 1, 10)
      // this.fiexdColStyle()
      // keyHandler(activeBill)
    },
    reRender(activeBill) {
      let tableId = `elTable${activeBill}`
      if (activeBill === '') {
        return
      }
      this.$nextTick(() => {
          this.$refs[tableId][0].doLayout();
      })
    },
    // 回到顶部
    returnTop(){
      
      document.querySelector('#app').scrollIntoView({
        'behavior' : 'smooth'
      })
      // window.scrollBy(0,-100);//Only for y vertical-axis
      // console.log(',,', document.body, document.body.scrollTop)
      // if(document.body.scrollTop > 0) { 
      //   let thiz = this
      //   setTimeout(function(){
      //     thiz.returnTop()
      //   },50);
      // }
    },
    

    // 全选
    handleCheckAllChange(val, id) {
        this.checkedOption[id] = val ? this.tableOption[id] : ['链接'];
        this.colWidthHandler(id)
        this.tableData[id] = val ? this.allTableData[id] : ['链接'];
        this.isIndeterminate[id] = false;
        this.checkLastColIsFile(id)
        this.fiexdColStyle()
    },
    // 单选
    handleCheckedTableChange(val, id) {
        // console.log('val', val)
        // let arr = [];
        // for (let i = 0; i < this.tableOption[id].length; i++) {
        //     if (val.indexOf(this.tableOption[id][i]) != -1) {
        //         arr.push(this.tableOption[id][i]);
        //     }
        // }
        // this.checkedOption[id] = arr;
        this.sortCheckedOption(id)
        this.tableData[id] = val.length == 0 ? [] : this.allTableData[id];
        this.colWidthHandler(id)
        let checkedCount = val.length;
        this.checkAll[id] = checkedCount === this.tableOption[id].length;
        this.isIndeterminate[id] = checkedCount > 0 && checkedCount < this.tableOption[id].length;
        
        this.checkLastColIsFile(id)
        this.fiexdColStyle()

        this.$forceUpdate()
    },
    sortCheckedOption(id) {
      let allOption = this.tableOption[id]
      let checkedOption = this.checkedOption[id]
      let o = []
      // console.log('allOption', allOption, checkedOption)
      checkedOption.forEach((item) => {
        let index = allOption.indexOf(item)
        if (index === -1) {
          // console.log('选项排序不对', item)
        }
        o[index] = item
      })
      o = o.filter((item) => item !== undefined)
      // console.log('o', o)
      this.checkedOption[id] = o
    }
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .centext {
    margin: 0.7rem 0;
    padding: 0 1.25rem;
    
    .title {
      text-align: left;
      // padding: 0 12rem;
      -webkit-line-clamp: 2;
      font-size: 1.375rem;
      font-weight: 300;
      line-height: 2.5rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      font-family: '宋体', 'Courier New', Courier, monospace;
      // display: flex;

      .time{
        flex: 1;
        text-align: right;
        font-weight: 400;
        font-size: 1.25rem;
      }
    }
    .text {
      text-align: left;
      padding: 0;
    }
    .summary {
      font-size: 1.0rem;
      // text-indent: 2rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
      font-weight: 300;
    }
    .bord {
      text-indent: 0;
    }
    /deep/ p {
      font-size: 1rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
      font-weight: 300;
    }
    .mode {
      justify-content: flex-start;
      margin: 1rem 0;
      div {
        font-size: 1.125rem;
      }
      .linkpath {
        font-size: 1rem;
        color: #1b289e;
        margin-right: 0.625rem;
      }
      .linkpath:hover {
        cursor: pointer;
        color: #5a71f0;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
        font-size: 1.125rem;
      }
    }

    .billDesc{
      height: 4rem;
      background: #F2F6FC;
      border-radius: 0.2rem;
      margin-bottom: 0.4rem;
    }
    .billListBoxContainer{
      display: flex;
    }
    .billListBox{
      background: #F2F6FC;
      /* padding: 0.4rem; */
      line-height: 1.8rem;
      display: flex;
      width: 100%;
      border-radius: 0.3rem;
      background-color: #4472C4;
      margin: 0 1rem;
      justify-content: space-between;
    }
    .billListTitle{
      color: white;
      font-size: 1rem;
      cursor: pointer;
      padding: 0.3rem;
      border-radius: 0.3rem;
      display: inline-block;
      text-align: center;
      width: 12rem;
      margin: 0.3rem 0.5rem;
    }
    .active {
      color: #409EFF;

    }
    .billListTitle:hover{
      // text-decoration:underline;
      // color: #409EFF;
      // background: #E4E7ED;
      background-image: linear-gradient(90deg, #1065fb, #1065fb 0, #40b2fb 100%, #40b2fb 0);
    }
    .billListHighLight{
      // background: #E4E7ED;
      background-image: linear-gradient(90deg, #1065fb, #1065fb 0, #40b2fb 100%, #40b2fb 0);
    }
    .boardContainer {
      padding: 0 1rem;
      background-color: white;
    }

    .title {
      height: 3.75rem;
      background-color: #8cb9e6;
      color: #0e4efd;
      font-size: 1.25rem;
      font-weight: 550;
      font-family: '微软雅黑';
      margin-bottom: 0.625rem;
      margin-top: 0rem;
      background-image: url(../../../assets/tabTitle.png
      );
      align-items:end;
      span {
        padding-bottom: 0.7rem;
        // border-bottom: 0.3rem solid #0e4efd;
      }
    }
    .benchTitle {
      background-image: linear-gradient(90deg, #1065fb, #1065fb 0, #40b2fb 100%, #40b2fb 0);
      height: 2.5rem;
      color: white;
      font-size: 1.2rem;
      font-weight: 330;
      display: flex;
      margin-bottom: 0.3rem;
      align-items: center;
      justify-content: center;
    }


    .billHead {
      height: 2.5rem;
      background: #c8d8f3;
      color: #2a579a;
      /* padding: 0.1rem; */
      display: flex;
      align-items: center;
      padding-left: 1rem;
      font-weight: 800;
    }
    .billTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5rem 0 0 0;
    }
  }

  .backtop{
    position: fixed;
    background-color: #EBEEF5;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    cursor: pointer;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .el-breadcrumb {
    display: none;
  }
  .centext {
    margin: 0.625rem;
    .title {
      text-align: left;
      -webkit-line-clamp: 2;
      font-size: 1rem;
      font-weight: 300;
      line-height: 2rem;
      margin-bottom: 0.625rem;
      margin-top: 1rem;
      font-family: '宋体', 'Courier New', Courier, monospace;
      
    }
    .text {
      text-align: left;
      padding: 0;
    }
    .summary {
      font-size: 1rem;
      // text-indent: 2rem;
      line-height: 1.8rem;
      margin-bottom: 0.325rem;
      font-weight: 300;
    }
    .bord {
      text-indent: 0;
    }
    /deep/ p {
      font-size: 1rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
      font-weight: 300;
    }
    .mode {
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 1rem 0;
      div {
        font-size: 1rem;
      }
      .linkpath {
        font-size: 1rem;
        color: #1b289e;
        margin-right: 0.625rem;
      }
      .linkpath:hover {
        cursor: pointer;
        color: #5a71f0;
      }
      img {
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 1rem;
        font-size: 1rem;
      }
    }

    .billDesc{
      height: 4rem;
      background: #F2F6FC;
      border-radius: 0.2rem;
      margin-bottom: 0.4rem;
    }
    .billListBox{
      background: #F2F6FC;
      padding: 0.4rem;
      line-height: 1.8rem;
      display: inline-block;
      margin: auto;
    }
    .billListTitle{
      margin-right: 0.2rem;
      color: #606266;
      font-size: 1rem;
      cursor:pointer;
      padding: 0.3rem;
      border-radius: 0.2rem;
    }
    .active {
      color: #409EFF;
    }
    .billListTitle:hover{
      // text-decoration:underline;
      // color: #409EFF;
      background: #E4E7ED;

    }
  }

  .backtop{
    position: fixed;
    background-color: #EBEEF5;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    cursor: pointer;
    z-index: 5;
  }
}

</style>
<style> 
/** 复选框样式 */
  .billdetail-box .el-checkbox-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  .billdetail-box .el-checkbox {
      margin: 0.2rem 0;
      line-height: 1.6;
  }
  .billdetail-box .el-checkbox__label{
    font-size: 0.9rem;
    padding-left: 0.23333rem;
    line-height: 0.9rem;
  }
  .billdetail-box .el-checkbox__inner{
      width: .8rem;
      height: .8rem;
      border-radius: .2rem;
  }
  .billdetail-box .el-checkbox__inner::after{
    height: 0.25rem;
    width: 0.25rem;
    left: 0.2rem;
    top: 0.14rem;
  }
  .billdetail-box .el-checkbox__input.is-indeterminate .el-checkbox__inner::before{
    top: 0.3rem;
  }

  /** 表格样式 */
  .billdetail-box .el-table {
    font-size: 0.9rem;
    margin-bottom: 0.6rem;
  }
  .billdetail-box .el-table .cell,
  .el-table th > .cell {
      display: inline-block;
      overflow: hidden;
      line-height: 2.6rem;
      word-break: break-word;
  }
  
  .billdetail-box .el-table td > .cell{
    line-height: 1.2rem;
  }
  .billdetail-box .el-table__empty-text{
    line-height: 2rem;
  }
  /* .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 0.01333rem solid rgba(0,0,0,.1);
    
  } */

  .billdetail-box .el-table .caret-wrapper{
    height: 1rem;
    width: 1rem;
  }
  /**表格标题排序箭头 */
  .billdetail-box .el-table .sort-caret{
    border: 0.32rem solid transparent;
    left: 0.36rem;
  }
  .billdetail-box .el-table .sort-caret.ascending{
    top: -0.24rem;
    border-bottom-color: #C0C4CC;
  }
  .billdetail-box .el-table .ascending .sort-caret.ascending{
    border-bottom-color: #409EFF;
  }
  .billdetail-box .el-table .sort-caret.descending{
    bottom: -0.20rem;
    border-top-color: #C0C4CC;
  }
  .billdetail-box .el-table .descending .sort-caret.descending{
    border-top-color: #409EFF;
  }
 
  /** 分页样式 */
  .billdetail-box .el-pagination{
    /* text-align: right; */
    text-align: center;
  }
  .billdetail-box .el-pagination button, .el-pagination span:not([class*=suffix]){
    font-size: 0.9rem;
    min-width: 1rem;
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .billdetail-box .el-pagination .el-select .el-input{
    width: 6rem;
    margin: 0 0.4rem;
  }
  .billdetail-box .el-pagination__sizes .el-input .el-input__inner{
    font-size: 0.9rem;
    border-radius: 0.2rem;
    margin-top: -0.3rem;
  }
  .billdetail-box .el-input--mini .el-input__inner{
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .billdetail-box .el-pager li {
    padding: 0 0.5rem;
    font-size: 0.8rem;
    min-width: 0.47333rem;
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .billdetail-box .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    margin: 0 0.3rem;
    min-width: 1.8rem;
    border-radius: 0.2rem;
  }
  .billdetail-box .el-pagination__editor.el-input{
    width: 2rem;
  }
  .billdetail-box .el-input{
    font-size: 0.9rem;
  }
  .billdetail-box .el-pagination__editor{
    line-height: 1.6rem;
    height: 1.6rem;
    border-radius: 0.2rem;
    margin: 0 0.4rem;
  }
  .billdetail-box .el-pagination__editor.el-input .el-input__inner {
    height: 1.6rem;
    border-radius: 0.2rem;
  }
  .el-select-dropdown__item {
    font-size: 0.8rem;
    padding: 0 0.26667rem;
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .el-pagination .el-input__suffix{
    right: 0.3rem;
    margin-top: -0.1rem;
  }
  .el-select-dropdown__wrap {
      max-height: 8rem;
  }
  .el-select .el-input .el-select__caret{
    font-size: 0.8rem;
  }
  .el-input__icon{
    width: 0.8rem;
  }
  /* .el-table__body-wrapper {
    height: 47.04rem !important;
  } */
  .el-loading-spinner{
    font-size: 2.5rem;
  }

  .dropDown {
    display: inline-block;
    position: relative;
    color: black;
    /* transform: translateY(0.8rem); */

  }
  .dropDown .el-dropdown-link {
      color: #409eff;
      font-size: 1rem;
      cursor: pointer;
  }
  .dropDownItem {
    display: inline-block;
    width: fit-content;
    padding: 0rem 1rem;
    
  }
  .dropDownList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 24rem;
    width: 18rem;
    border-top: 0.1rem solid #80808042;
    margin-top: 0.5rem;
  }

  .dropDownItem.el-checkbox-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  .dropDownItem .el-checkbox {
      margin: 0.2rem 0;
      line-height: 1.6;
  }
  .dropDownItem .el-checkbox__label{
    font-size: 0.9rem;
    padding-left: 0.23333rem;
    line-height: 0.9rem;
  }
  .dropDownItem .el-checkbox__inner{
      width: .8rem;
      height: .8rem;
      border-radius: .2rem;
  }
  .dropDownItem .el-checkbox__inner::after{
    height: 0.25rem;
    width: 0.25rem;
    left: 0.2rem;
    top: 0.14rem;
  }
  .dropDownItem .el-checkbox__input.is-indeterminate .el-checkbox__inner::before{
    top: 0.3rem;
  }
  .tableBox {
    position: relative;
    transform: translateY(-0.8rem);
  }
  
  /* #4472C4 */
  .el-table .rowbg_1 {
    background: #E9EBF5;
  }
  .el-table .rowbg_0 {
    background: #CFD5EA;
  }
  .el-button--text {
    color: #3335FA;
    font-weight: bolder;
  }
  /* .el-table__row .rowbg_0 .hover-row {
    background-color: unset !important;
  } */
  .el-table__body tr.hover-row.current-row>td.el-table__cell, .el-table__body tr.hover-row.el-table__row--striped.current-row>td.el-table__cell,  .el-table__body tr.hover-row.el-table__row--striped>td.el-table__cell, .el-table__body tr.hover-row>td.el-table__cell {
    background-color:unset !important;
  }
  /* tr {pointer-events: none;} */
</style>



