<template>
  <div>
    <div class="box" v-if="istrue">
    <crumbs :list="list" :pagenum="4" :chinum="secondary" />
    <div class="centext">
      <div class="flexspb">
        <div class="title">{{ drtail.name }}</div>    
        <div @click="gjsq" v-if="infoUser.name" class="btn btn1 flexcent">工具下载申请</div>
        <div @click="loginCallback" v-else class="btn btn1 flexcent">工具下载申请</div>
        <!-- <div @click="download" class="btn flexcent">下载</div> -->
      </div>
      <div class="summary">{{ drtail.summary }}</div>
      <div class="ql-editor" v-html="htmltitle"></div>
    </div>
  </div>

    <el-dialog
            class="dialogBox"
            title="工具下载申请"
            :visible.sync="dialogVisible"
            width="30%"
            >
          <div style="padding-bottom: 20px;">
            <license/>
            <div style="overflow: hidden; text-align: center;">
              <el-button @click="dialogVisible = false" style="font-size:1rem; padding: 0.5rem; margin-right: 10px; ">不接受</el-button>
              <el-button type="primary" style="font-size:1rem; padding: 0.5rem;" @click="handlesure">接 受</el-button>
            </div>
          </div>
    </el-dialog>
<!--  提示信息  -->
    <el-dialog
            class="dialogBox1"
            title="信息提示"
            :visible.sync="dialogVisibleInfo"
            width="30%"
           >
      <div style="" class="txt-model">
        {{infoUser.name}}已接受许可协议，请等待管理员审核。工具链接将发送至联系人邮箱，有问题请按网页信息联系工作组。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleInfo = false" style="font-size:1rem; padding: 0.5rem; margin-right: 10px;">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
            class="dialogBox1"
            title="信息提示"
            :visible.sync="dialogVisible2"
            width="30%"
           >
      <div style="" class="txt-model">
        已申请通用计算机性能基准工具。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible2 = false" style="font-size:1rem; padding: 0.5rem; margin-right: 10px;">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import crumbs from "../../all/crumbs"; //面包屑
import license from "./License.vue";
export default {
  name: "tooldetail", //工具下载
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    id: {
      typeof: [String],
    },
  },
  components: {
    crumbs,
    license,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisibleInfo: false,
      secondary: 1, //二级菜单的选中样式
      list: ["首页", "工具下载", ""],
      narlist: [], //当前导航栏数组
      drtail: {}, //当前内容
      htmltitle: "", //当前富文本
      isdown: false, //是否可以下载
      istrue:true,
      infoUser:{
        name:''
      }
    };
  },
  created() {
    this.secondary = this.chinum; //同步一级菜单
    this.getnarlist()
  },
  mounted() {
    this.getTool();
    if(this.until.cacheSync("get", "getUserNew")){
      this.infoUser = this.until.cacheSync("get", "getUserNew");
    }
  },
  methods: {
    loginCallback(){
// 
      this.until.cacheSync("set", 'toolInfo', this.$route.query);
      this.until.jump.call(this, "/uiadmin", "href");
    },
    // 进入页面加载导航栏数据
    getnarlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.narlist = res.data[4].children;
        this.list[2] = this.narlist[this.secondary].name;
        this.istrue=false
        this.$nextTick(()=>{
          this.istrue=true
        })
      });
    },
    gjsq(){
      console.log("====gjsq 下载 =======" + this.drtail)
      const reg1 = RegExp(/通用计算性能测试基准/);
      const reg2 = RegExp(/CPU/);

      if(reg1.test(this.drtail.name)||reg2.test(this.drtail.name)) {
        this.dialogVisible=true
      }
      else {
        this.handlesure2()
      }
    },
    handlesure(){
      this.$api.getSave({
        toolId: this.drtail.id,
        toolName: this.drtail.name
      }).then((res) => {
        console.log(res);
        this.dialogVisible = false;
        this.dialogVisibleInfo = true;
      });

    },
    handlesure2(){
      // console.log(" ======  handler suer2 ==== ", this.drtail.name)
      this.$api.getSave({
        toolId: this.drtail.id,
        toolName: this.drtail.name
      }).then(() => {
        // console.log("sure2 res: ", res);
        this.dialogVisible2 = true
      });

    },
    handleClose(){},
   //  点击下载判断用户权限
    download() {
      // var info = this.until.cacheSync("get", "pigx-userInfo");
      // if (info) {
      //   var obj = {
      //     fileId: this.id,
      //     userId: info.content.userId,
      //   };
      //   this.$api.power(obj).then((res) => {
      //     if (res.data == "toDownload") {
            window.open(
              this.$base.baseUrl +
                `/admin/sys-file/${this.drtail.fileRef}/${this.drtail.fileName}`,
              "_parent"
            );
      //     } else {
      //       this.$message.warning("权限不足");
      //     }
      //   });
      // } else {
      //   this.$message.warning("1请登录后进行下载");
      // }
    },
    //    获取富文本信息
    gethtml(id) {
      this.$api.getWorkHtml(id).then((res) => {
        this.htmltitle = res.data.content;
      });
    },
    //  获取详情接口
    getTool() {

      this.$api.getToolDetail(this.id).then((res) => {
        this.drtail = res.data;
        this.gethtml(res.data.htmlRef);

      });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .centext {
    margin: 1.25rem 0;
    padding: 0 1.25rem;
    .title {
      -webkit-line-clamp: 2;
      font-size: 1.375rem;
      font-weight: 550;
      line-height: 2rem;
      margin-bottom: 1.25rem;
    }
    .summary {
      padding-left: 1.25rem;
      margin-bottom: 2.5rem;
    }
    .btn {
      margin-bottom: 1.25rem;
      width: 12rem;
      height: 2.4rem;
      border-radius: 0.2rem;
      background-color: #409eff;
      color: #fff;
    }
    .btn:hover {
      background-color: #061388;
      cursor: pointer;
    }
    .btn:active {
      opacity: 0.8;
    }
    /deep/ p {
      font-size: 1.125rem;
      line-height: 2rem;
      margin-bottom: 0.625rem;
    }
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .el-breadcrumb {
    display: none;
  }
  .centext {
    margin: .625rem;
    .title {
      -webkit-line-clamp: 2;
      font-size: 1rem;
      font-weight: 550;
      line-height: 2rem;
      margin-bottom: .625rem;
    }
    .summary {
      padding-left: .625rem;
      margin-bottom: .625rem;
    }
    .btn {
      margin-bottom: .625rem;
      width: 5rem;
      height: 2rem;
      border-radius: 0.2rem;
      background-color: #409eff;
      color: #fff;
    }
    .btn1{
      width: 8rem;
    }
    .btn:active {
      opacity: 0.8;
    }
    /deep/ p {
      font-size: 1rem;
      line-height: 2rem;
      margin-bottom: 0.625rem;
    }
  }
}
</style>
<style>
  .txt-model{
    font-size: 1.5rem;
    padding-top: 3rem;
  }
  .dialogBox .el-dialog{
      width: 50% !important;
      height: 40rem;
  }
  .dialogBox1 .el-dialog{
      width: 50% !important;
      height: 20rem;
  }
  .dialogBox  .el-dialog__header, .dialogBox1 .el-dialog {
    padding: 1rem 1rem 1rem;
  }
  .dialogBox  .el-dialog__header .el-dialog__title, .dialogBox1  .el-dialog__header .el-dialog__title {
    line-height: 1.5rem;
    font-size: 1.5rem;
    color: #303133;
  }
  .dialogBox  .el-dialog__header .el-dialog__headerbtn,  .dialogBox1  .el-dialog__header .el-dialog__headerbtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 1rem;
  }
  .dialogBox .el-dialog__body {
    padding: 1rem 2rem;
    color: #606266;
    font-size: 1rem;
    word-break: break-all;
    height: 34rem;
    overflow-y: auto;
  }
  .dialogBox1 .el-dialog__body {
    padding: 1rem 2rem;
    color: #606266;
    font-size: 1rem;
    word-break: break-all;
    height: 10rem;
    overflow-y: auto;
  }
  .dialogBox1 .el-dialog__footer{
      padding: 1rem;
  }
  @media screen and (max-width: 1023px) {
    .dialogBox .el-dialog {
      width: 90% !important;
      height: 38rem;
    }
    .dialogBox .el-dialog__body {
      padding: 1rem 2rem;
      color: #606266;
      font-size: 1rem;
      word-break: break-all;
      height: 32rem;
      overflow-y: auto;
    }
    .dialogBox1 .el-dialog {
      width: 90% !important;
      height: 20rem;
    }
    .txt-model{
      font-size: 1rem;
      padding-top: 3rem;
    }
    .dialogBox1 .el-dialog__footer{
      text-align: center;
    }
  }
</style>
