<template>
  <!-- 首页代码 -->
  <div class="mainpage">
    <!-- 封装的头部和尾部的组件 -->
    <pagebox
      v-if="istrue"
      @changenar="changenar"
      :pagenum="primary"
      :chinum="secondary"
    >
      <div id="homebox">
        <div id="pagebox">
          <div v-if="nowtitle == '基准标准' || nowtitle == '标准明细'">
            <cantlast
              :list="cumdlist"
              :id="workid"
              :pagenum="primary"
              :chinum="secondary"
            />
          </div>
          <div v-if="nowtitle == '基准工具'">
            <toolast
              :list="cumdlist"
              :id="workid"
              :pagenum="primary"
              :chinum="secondary"
            />
          </div>
          <div v-if="nowtitle == '性能基准'">
            <marklast
              :list="cumdlist"
              :id="workid"
              :pagenum="primary"
              :chinum="secondary"
            />
          </div>
        </div>
      </div>
    </pagebox>
  </div>
</template>

<script>
import pagebox from "../../components/page/pagebox"; //封装的头部和尾部的组件
import cantlast from "../../components/page/last/cantlast"; //封装的头部和尾部的组件
import marklast from "../../components/page/last/marklast"; //封装的头部和尾部的组件
import toolast from "../../components/page/last/toolast"; //封装的头部和尾部的组件

export default {
  data() {
    return {
      primary: 1, //一级菜单的选中样式
      secondary: 1, //二级菜单的选中样式
      istrue: true, //刷新页面
      workid: "", //当前id
      cumdlist: [], //面包屑
      nowtitle: "", //
    };
  },
  components: {
    pagebox, //全局引用页面样式
    cantlast,
    marklast,
    toolast,
  },
  //   接受页面的参数
  created() {
    this.getdeatil();
    document.documentElement.scrollTop = 0;
  },
  methods: {
    getdeatil() {
      this.nowtitle = this.$route.query.title;
      this.workid = this.$route.query.id;
      this.primary = this.$route.query.pagenum;
      this.secondary = this.$route.query.chinum;
      this.cumdlist = this.$route.query.list;
    },
    // 接受传输过来的值进行页面的切换
    changenar(e) {
      this.primary = e.pagenum; //一级菜单的选中样式
      this.secondary = e.chinum; //二级菜单的选中样式
      //  进行路由跳转
      //  进行路由跳转
      var list = this.until.cacheSync("get", "navigation").data;
      this.until.jump.call(this, list[this.primary].path, "push", {
        pagenum: this.primary,
        chinum: this.secondary,
      });
    },
  },
};
</script>

<style scoped lang="less">
// 当前页面最大的盒子（配置背景色
.mainpage {
  min-height: 100vh;
  width: 100%;
  background-color: #e2e2e217;
}
.orderbox {
  align-items: initial;
  margin-top: 1.25rem;
}
.lefttab {
  width: 15rem;
  margin-right: 0.625rem;
}
.rightbox {
  flex: 1;
}
</style>
