<template>
  <div class="box">
    <crumbs :list="list" :pagenum="2" :chinum="secondary" />
    <card title="基准说明" class="centext">
      <div class="title">{{ drtail.title }}</div>
      <div class="summary">{{ drtail.summary }}</div>
      <div class="ql-editor" v-html="htmltitle"></div>
    </card>
    <div>
      <describe @todetail="firdetail" title="基准标准" :list="detailist" />
    </div>
    <div>
      <describe @todetail="thirdetail" title="基准工具" :list="toollist" />
    </div>
    <div>
      <feedback :id="id" :list="feedlist" />
    </div>
  </div>
</template>

<script>
import crumbs from "../../all/crumbs"; //面包屑
import card from "../../all/card"; //面包屑
import describe from "../../all/describe";
import feedback from "./feedback"; //联系人
export default {
  name: "markdetail", //性能基准
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    id: {
      typeof: [String],
    },
  },
  components: {
    crumbs,
    describe,
    feedback,
    card,
  },
  data() {
    return {
      secondary: 0, //二级菜单的选中样式
      list: ["首页", "性能基准", ""],
      narlist: this.until.cacheSync("get", "navigation").data[2].children, //当前导航栏数组
      drtail: {}, //当前内容
      htmltitle: "", //当前富文本
      detailist: [],
      toollist: [],
      feedlist: [
        {
          id: "unitInfo",
          title: "单位名称",
          value: "",
          max: 128,
          plalder: "请输入单位名称",
        },
        {
          id: "linkman",
          title: "联系人",
          value: "",
          max: 128,
          plalder: "请输入联系人",
        },
        {
          id: "linktele",
          title: "联系人电话",
          value: "",
          max: 11,
          plalder: "请输入联系人电话",
        },

        {
          id: "email",
          title: "联系人邮箱",
          value: "",
          max: 30,
          plalder: "请输入联系人邮箱",
        },
        {
          id: "content",
          title: "反馈内容",
          value: "",
          plalder: "请输入反馈内容",
        },
      ],
    };
  },
  created() {
    this.secondary = this.chinum; //同步一级菜单
    this.list[2] = this.narlist[this.secondary].name;
  },
  mounted() {
    this.getbenmuch();
  },
  methods: {
    firdetail(id) {
      this.list[3] = this.drtail.title;
      var obj = {
        id: id,
        title: "基准标准",
        list: this.list,
        chinum: this.secondary,
        pagenum: 2,
      };
      //  进行路由跳转
      this.until.jump.call(this, "/lastdetail", "push", obj);
    },
    thirdetail(id) {
      this.list[3] = this.drtail.title;
      var obj = {
        id: id,
        title: "基准工具",
        list: this.list,
        chinum: this.secondary,
        pagenum: 2,
      };
      //  进行路由跳转
      this.until.jump.call(this, "/lastdetail", "push", obj);
    },
    //    获取富文本信息
    gethtml(id) {
      this.$api.getWorkHtml(id).then((res) => {
        this.htmltitle = res.data.content;
      });
    },
    // 性能基准
    getbenmuch() {
      this.$api.benchmarkDetail(this.id).then((res) => {
        this.drtail = res.data;
        var id = res.data.introduce;
        // 相关标准列表
        this.$api.getstandlist({ specCode: res.data.code }).then((res) => {
          this.detailist = res.data;
        });
        this.$api
          .getcppbtoolList({
            // title:'',
            status: 1,
            // name:"",
            specCode: this.drtail.code,
            typeCode: 0,
          })
          .then((result) => {
            this.toollist = result.data;
          });
        this.gethtml(id);
      });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
   /deep/ .box{
      background: #ffffff;
      border: .0625rem solid #ececec;
    }
    /deep/ .box:hover{
      box-shadow: none;
    }
  .centext {
    margin: 1.25rem 0;
    padding: 0 1.25rem;
    .title {
      text-align: center;
      padding: 0 12rem;
      -webkit-line-clamp: 2;
      font-size: 1.375rem;
      font-weight: 550;
      line-height: 2.5rem;
      margin-bottom: 1.25rem;
    }
    .summary {
      font-size: 1.125rem;
      text-indent: 2rem;
      line-height: 2rem;
      margin-bottom: 0.625rem;
    }
    /deep/ p {
      font-size: 1.125rem;
      text-indent: 2rem;
      line-height: 2rem;
      margin-bottom: 0.625rem;
    }
  }
  /deep/ .box {
    .box {
      background-color: #ffffff;
      border: 0.0625rem solid #eeeeee;
      margin-bottom: 1.25rem;
    }
    .cardhead {
      height: 3.75rem;
      font-size: 1.25rem;
    }
    .more {
      display: none;
    }
  }
  /deep/ .box:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .el-breadcrumb {
    display: none;
  }
  .centext {
    margin: 0.625rem;
    .title {
      text-align: center;
      -webkit-line-clamp: 3;
      font-size: 1rem;
      font-weight: 550;
      line-height: 2.5rem;
      margin-bottom: 0.625rem;
    }
    .summary {
      font-size: 1rem;
      text-indent: 2rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
    }
    /deep/ p {
      font-size: 1rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
    }
  }
  /deep/ .box {
    .box {
      margin: 0.625rem;
      width: 95%;
      background-color: #ffffff;
      border: 0.0625rem solid #eeeeee;
    }
    .cardhead {
      height: 2.5rem;
      font-size: 15rem;
    }
    .more {
      display: none;
    }
  }
  /deep/ .box:hover {
    box-shadow: none;
  }
}
</style>
