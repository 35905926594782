<template>
  <div class="box">
    <crumbs :list="list" :pagenum="primary" :chinum="secondary" />
    <div class="centext">
      <div class="flexspb">
        <div class="title">{{ drtail.name }}</div>
        <div
          v-if=" drtail.fileName"
          @click="download"
          class="btn flexcent"
        >
          下载
        </div>
      </div>
      <div class="ql-editor" v-html="htmltitle"></div>
    </div>
  </div>
</template>

<script>
import crumbs from "../../all/crumbs"; //面包屑
export default {
  name: "toolast", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    id: {
      typeof: [String],
    },
    pagenum: {
      typeof: [Number],
    },
    list: {
      typeof: [Array],
    },
  },
  components: {
    crumbs,
  },
  data() {
    return {
      primary: 0, //一级菜单
      secondary: 1, //二级菜单的选中样式
      drtail: {}, //当前内容
      htmltitle: "", //当前富文本
      isdown: false, //是否可以下载
    };
  },
  created() {
    this.secondary = this.chinum; //同步一级菜单
    this.primary = this.pagenum;
  },
  mounted() {
    this.getTool();
  },
  methods: {
   //  点击下载判断用户权限
    download() {
      // var info = this.until.cacheSync("get", "pigx-userInfo");
      // if (info) {
      //   var obj = {
      //     fileId: this.id,
      //     userId: info.content.userId,
      //   };
      //   this.$api.power(obj).then((res) => {
      //     if (res.data == "toDownload") {
            window.open(
              this.$base.baseUrl +
                `/admin/sys-file/${this.drtail.fileRef}/${this.drtail.fileName}`,
              "_parent"
            );
      //     } else {
      //       this.$message.warning("权限不足");
      //     }
      //   });
      // } else {
      //   this.$message.warning("3请登录后进行下载");
      // }
    },
    //    获取富文本信息
    gethtml(id) {
      this.$api.getWorkHtml(id).then((res) => {
        this.htmltitle = res.data.content;
      });
    },
    //  获取详情接口
    getTool() {

      this.$api.getToolDetail(this.id).then((res) => {
        this.drtail = res.data;
        this.gethtml(res.data.htmlRef);
      });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .centext {
    margin: 1.25rem 0;
    .title {
      -webkit-line-clamp: 2;
      font-size: 1.375rem;
      font-weight: 550;
      line-height: 2rem;
      margin-bottom: 1.25rem;
    }
    .btn {
      margin-bottom: 1.25rem;
      width: 12rem;
      height: 2.4rem;
      border-radius: 0.2rem;
      background-color: #409eff;
      color: #fff;
    }
    .btn:hover {
      background-color: #061388;
      cursor: pointer;
    }
    .btn:active {
      opacity: 0.8;
    }
    /deep/ p {
      font-size: 1.125rem;
      line-height: 2rem;
      margin-bottom: 0.625rem;
    }
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .el-breadcrumb {
    display: none;
  }
  .centext {
    margin: 0.625rem;
    .title {
      -webkit-line-clamp: 2;
      font-size: 1rem;
      font-weight: 550;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
    }
    .btn {
      margin-bottom: 0.325rem;
      width: 5rem;
      height: 2rem;
      border-radius: 0.2rem;
      background-color: #409eff;
      color: #fff;
    }
    .btn:active {
      opacity: 0.8;
    }
    /deep/ p {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.625rem;
    }
  }
}
</style>
