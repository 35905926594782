<template>
  <div class="breakbox">
    <!-- 头部 -->
    <div v-if="istrue" class="topheader">
      <!-- 引入的头部组件（传入页面数据 -->
      <headerbox
        :list="list"
        @changenar="changenar"
        :pagenum="primary"
        :chinum="secondary"
      />
    </div>
    <div class="leftnar" id="homebox">
      <div id="pagebox">
        <div class="tab">
          <leftab
            @changenar="changenar"
            :pagenum="primary"
            :chinum="secondary"
          />
        </div>
      </div>
    </div>
    <div id="homeboxBox">
      <div id="pagebox" class="cnetbox">
        <div class="leftbox" />
        <div class="boxpage" v-if="istrue && narlist.length > 0">
          <criterion
            @scolist="scolist"
            :narlist="narlist"
            @changenar="changenar"
            :chinum="secondary"
          />
        </div>
      </div>
    </div>
    <div>
      <footerbox
        :list="list"
        @changenar="changenar"
        :pagenum="primary"
        :chinum="secondary"
      />
    </div>
  </div>
</template>

<script>
//  引入的头部组件
import headerbox from "@/components/header/headerbox";
//  引入的尾部组件
import footerbox from "@/components/footer/footerbox";
//  引入的导航组件
import leftab from "@/components/all/leftab";
//  引入的页面组件
import criterion from "../../components/page/criterion/criterion";

const defaultPrimary = 3
export default {
  data() {
    return {
      list: [], //导航栏数组
      primary: defaultPrimary, //一级菜单的选中样式
      secondary: 0, //二级菜单的选中样式
      istrue: true, //刷新导航栏数据
      socllist: [0, 300],
      narlist: [],
    };
  },
  created() {
    this.secondary = this.$route.query.chinum;
  },
  mounted() {
    // 进入页面加载导航栏数据
    this.getlist();
  },
  components: {
    headerbox, //引入的头部组件
    footerbox, //引入的尾部组件
    criterion, //引入的页面组件
    leftab, //引入的导航组件
  },
  methods: {
    scolist(list) {
      this.socllist = list;
    },
    // 进入页面加载导航栏数据
    getlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.list = res.data;
        this.narlist = res.data[defaultPrimary].children;
      });
    },
    // 刷新当前页面
    refresh() {
      this.istrue = false;
      this.$nextTick(() => {
        this.istrue = true;
      });
    },
    // 点击导航传出当前选中菜单
    changenar(e) {
      this.primary = e.pagenum; //一级菜单的选中样式
       if (this.primary != defaultPrimary||this.secondary != e.chinum) {
        this.secondary = e.chinum; //二级菜单的选中样式
        this.until.jump.call(this, this.list[this.primary].path, "push", {
          pagenum: this.primary,
          chinum: this.secondary,
        });
        this.refresh();
      }
    },
  },
};
</script>

<style scoped lang="less">
.breakbox {
  background-color: #e2e2e217;
}
.topheader {
  position: sticky;
  z-index: 300;
  top: -5rem;
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 1024px) {
  .leftnar {
    height: 20rem !important;
    position: sticky;
    z-index: 10;
    top: 5rem;
    .tab {
      width: 15rem;
    }
  }
  .cnetbox {
    min-height: 39rem;

    display: flex;
    margin-top: -20rem;
    .leftbox {
      width: 15rem;
      margin-right: 1.25rem;
    }
    .boxpage {
      flex: 1;
      z-index: 200;
    }
  }
  .mainpage {
    min-height: 60vh;
  }
}
@media screen and (max-width: 1023px) {
  .mainpage {
    min-height: 67.5vh;
  }
  .leftnar {
    display: none !important;
  }
}
</style>
