<template>
  <div class="box" v-if="istrue">
    <crumbs :list="list" :pagenum="1" :chinum="secondary" />
    <div class="centext">
      <div class="title">{{ drtail.title }}</div>
      <div class="time flexcent">{{ drtail.pushTime }}</div>
      <div class="ql-editor" v-html="htmltitle"></div>
    </div>
  </div>
</template>

<script>
import crumbs from "../../all/crumbs"; //面包屑
export default {
  name: "workdetail", //工作组
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    id: {
      typeof: [String],
    },
  },
  components: {
    crumbs,
  },
  data() {
    return {
      secondary: 1, //二级菜单的选中样式
      list: ["首页", "工作组活动", ""],
      narlist: [], //当前导航栏数组
      drtail: {}, //当前内容
      htmltitle: "", //当前富文本
      istrue:true,
    };
  },
  created() {
    this.secondary = this.chinum; //同步一级菜单
    this.getlist()
  },
  mounted() {
    this.getdetail();
  },
  methods: {
    // 进入页面加载导航栏数据
    getlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.narlist = res.data[1].children;
        this.list[2] = this.narlist[this.secondary].name;
        this.istrue=false
        this.$nextTick(()=>{
          this.istrue=true
        })
      });
    },
    getdetail() {
      this.$api.getWorkDetail(this.id).then((res) => {
        this.drtail = res.data;
        this.gethtml(res.data.htmlRef);
      });
    },
    // 获取富文本信息
    gethtml(id) {
      this.$api.getWorkHtml(id).then((res) => {
        this.htmltitle = res.data.content;
      });
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .centext {
    margin: 1.25rem 0;
    // min-height: 80vh;
    .title {
      text-align: center;
      padding: 0 12rem;
      -webkit-line-clamp: 2;
      font-size: 1.375rem;
      font-weight: 550;
      line-height: 2.5rem;
      margin-bottom: 1.25rem;
    }
    .time {
      font-size: 1.125rem;
      margin-bottom: 1.25rem;
    }
    /deep/ .ql-align-justify {
      font-size: 1.125rem;
      text-indent: 2rem;
      line-height: 2rem;
      margin-bottom: 0.625rem;
    }
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .el-breadcrumb {
    display: none;
  }
  .centext {
    margin: 0.625rem;
    min-height: 80vh;
    .title {
      text-align: center;
      padding: 0 0.625rem;
      -webkit-line-clamp: 3;
      font-size: 1rem;
      font-weight: 550;
      line-height: 1.5rem;
      margin-bottom: 1.25rem;
    }
    /deep/ .ql-align-justify {
      font-size: 0.8rem;
      text-indent: 2rem;
      line-height: 1.5rem;
      margin-bottom: 0.625rem;
      letter-spacing: 0.125rem;
    }
    .ql-editor {
      min-height: 100vh;
    }
  }
}
</style>
