<template>
  <div class="billdetail-box">
    <div class="box" v-if="istrue">
      <crumbs :list="list" :pagenum="3" :chinum="secondary" />
      <div class="centext" v-if="chinum != 2">
        <!-- <div class="billDesc"></div> -->
        <div v-if="(billList.length > 0)" class="billListBox">
          <!-- 榜单： -->
          <span 
            :class="{'billListTitle':true}" 
            v-for="(item, i) of billList" 
            :key="i"
            @click="jumpHandler(item.id)"
          >
            {{item.name}}
          </span>
        </div>
        
        <div v-for="(value, key, i) in pages" :key="i" :id="('bill_detail_'+key)">
          <div class="title">{{ drtail[key].name }}</div>
          <div class="summary">{{ drtail[key].summary }}</div>
          <div class="ql-editor" v-html="htmlBillContent[key]"></div>
          
          <el-row :gutter="20">
            <el-col :span="3">
              <div>
                  <el-checkbox 
                    v-if="allTableData[key].length != 0"
                    :indeterminate="isIndeterminate[key]"
                    v-model="checkAll[key]"
                    @change="(val) => handleCheckAllChange(val, key)"
                  >全选</el-checkbox>
                  <el-checkbox-group
                    v-model="checkedOption[key]"
                    @change="(val) => handleCheckedTableChange(val, key)"
                  >
                    <el-checkbox
                        v-for="(item, index) in tableOption[key]"
                        :key="index"
                        :label="item"
                    ></el-checkbox>
                  </el-checkbox-group>
              </div>
            </el-col>
            <el-col :span="21">
              <el-table
                  :data="tableData[key]"
                  style="width: 500%"
                  index
                  stripe
                  :fit="false"
                  :header-cell-style="{'background': 'rgb(236, 245, 255)'}"
                  type="index"
                  border
                  @sort-change="({ prop, order }) => tableSortChange({ prop, order }, key)"
                  >
                  <el-table-column
                      v-for="(v, i) in checkedOption[key]"
                      :key="i"
                      :prop="v"
                      :label="v"
                      :align="'center'"
                      show-overflow-tooltip
                      :width="colWidth[key][i]"
                      :header-align="'center'"
                      :sortable="(dowloadBtn[key] && i == checkedOption[key].length - 1) ? false : 'custom'"
                      :fixed="(dowloadBtn[key] && i == checkedOption[key].length - 1) ? 'right' : false"
                  >
                  <template slot-scope="scope">
                    <el-button v-if="i == checkedOption[key].length - 1 && scope.row[v].indexOf('/admin/sys-file') > 0" @click="downloadFile(scope.row[v])" type="text" size="small">下载报告</el-button>
                    <span v-else>
                      <b v-if="boldCol[key].indexOf(v) > -1 && colorCol[key].indexOf(v) > -1 && color[key]" :style="'color:'+color[key]">{{lengthSub(scope.row[v])}}</b>
                      <b v-else-if="boldCol[key].indexOf(v) > -1">{{lengthSub(scope.row[v])}}</b>
                      <span v-else-if="colorCol[key].indexOf(v) > -1 && color[key]" :style="'color:'+color[key]">{{lengthSub(scope.row[v])}}</span>
                      <span v-else>{{lengthSub(scope.row[v])}}</span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                  @size-change="(val) => sizeChangeHandle(val, key)"
                  @current-change="(val) => currentChangeHandle(val, key)"
                  :current-page="pages[key].current"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pages[key].size"
                  :total="pages[key].total"
                  background
                  layout="total, prev, pager, next, jumper">
              </el-pagination>
            </el-col>
          </el-row>
          
          <div class="ql-editor" v-html="htmltitle[key]"></div>
        </div>
        

      </div>
      <div class="centext" v-if="chinum == 2">
        <div class="title text">
          <span>{{ billist.name }}</span>
          <div class="time">
            <span>开始日期 ：{{ billist.startTime }}</span>   
            <span style="opacity: 0;">111</span>
            <span>截止日期 ：{{billist.finishTime}}</span></div>
        </div>
        <div class="flexcent mode">
          <div
            class="linkpath"
            @click="tolink(item.id, index)"
            v-for="(item, index) in detaillist"
            :key="index"
          >
            [{{ item.name }}]
          </div>
        </div>
        <div class="bord summary">{{ billist.summary }}</div>
        <div class="title text">测试要求</div>
        <div class="ql-editor" v-html="billist.demandHtml"></div>
        <div class="title text">报名表</div>
        <div class="ql-editor" v-html="billist.formHtml"></div>
        <div class="flexcent mode">
          <div>报名方式 :</div>
          <img src="../../../assets/qq.png" />
          <img src="../../../assets/wx.png" />
          <img src="../../../assets/yx.png" />
        </div>
      </div>
    </div>
    <div class="backtop" style="right: 40px; bottom: 40px;" @click="returnTop"><i class="el-icon-caret-top"></i></div>
  </div>
</template>

<script>
import crumbs from "../../all/crumbs"; //面包屑
export default {
  name: "billdetail", //基准榜单
  props: {
    // 当前的页码(1级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
    id: {
      typeof: [String],
    }
  },
  components: {
    crumbs,
  },
  data() {
    return {
      secondary: 1, //二级菜单的选中样式
      list: ["首页", "基准榜单", ""],
      narlist: [], //当前导航栏数组
      drtail: {}, //当前内容
      htmltitle: {}, //当前富文本 结果描述
      htmlBillContent: {},  // 富文本  榜单描述
      drtailReport:[], // 测试报告
      billist: {}, //榜单数据
      detaillist: [], //
      istrue:true,

      pages: {},
      dowloadBtn: {},
      checkAll: {},
      isIndeterminate: {},
      checkedOption: {},
      tableOption: {},
      allTableData: {},
      tableData: {},
      showCol: {},
      boldCol: {},
      colorCol: {},
      color: {},
      col60:['序号'],
      col120:['Typical分数', 'Max频率', '基频', 'Extreme分数', '测试日期'],
      col180:['计算机型号'],
      col220:['编译器', 'CPU型号'],
      col260:['厂商', '内存', '测试者'],
      colWidth: {},
      billList: [] // 榜单列表
    };
  },
  created() {
    if (this.secondary != 2) {
      this.getBillboardList()
      // this.getdetail();
    } 
    this.secondary = this.chinum; //同步一级菜单
    this.getnarlist()
  },
  mounted() {
    if (this.secondary == 2) { 
      this.getthidlist();
      this.getlist();
    }
  },
  methods: {
    // 进入页面加载导航栏数据
    getnarlist() {
      // 发起请求
      this.$api.getNavigation({}).then((res) => {
        // 把数组放到data中
        this.narlist = res.data[3].children;
        this.list[2] = this.narlist[this.secondary].name;
        this.istrue=false
        this.$nextTick(()=>{
          this.istrue=true
        })
      });
    },
    tolink(id, index) {
      this.list[3] = this.billist.name;
      var obj = {
        id: id,
        title: "",
        list: this.list,
        chinum: this.secondary,
        pagenum: 3,
      };
      if (index == 2) {
        obj.title = "基准工具";
      } else if (index == 1) {
        obj.title = "标准明细";
      } else if (index == 0) {
        obj.title = "性能基准";
      }
      //  进行路由跳转
      this.until.jump.call(this, "/lastdetail", "push", obj);
    },
    // 获取榜单列表
    getBillboardList(){
      this.$api
          .billboardList({
            current: 1, //当前页
            size: 20, //页码数量
            title: "", //标题
            status: 1, //状态
            // cateroyCode: num, //活动分类
            name: "", //榜单名称
            billboardId: this.id // 大榜单id
          })
          .then((res) => {
            this.billList = res.data.records; 
            this.getdetail()
          });
    },
    getdetail() { 
      this.init()
      for(let item of this.billList){
        this.$api.getcppbtoolresult(item.id).then((res) => {
          let key = this.keyHandler(item.id)
          this.drtail[key] = res.data;
          if(res.data.viewConfig){
            let json = JSON.parse(res.data.viewConfig)
            this.showCol[key] = json.showCol
            this.boldCol[key] = json.boldCol
            this.colorCol[key] = json.colorCol
            this.color[key] = json.color
          }

          this.gethtml(res.data.htmlRef, key);
          this.getHtmlBill(res.data.htmlBill, key)

          this.getCsvPage(item.id, 1, 10)

          // this.$api.getcppbtoolresult(this.id + '/report').then((res) => {
          //   this.drtailReport = res.data
          //   this.showCVS()
          // });
        });
      }
      // this.$api.getcppbtoolresult(id).then((res) => {
      //   this.drtail = res.data;
      //   if(this.drtail.viewConfig){
      //     let json = JSON.parse(this.drtail.viewConfig)
      //     console.log('viewConfig::', json)
      //     this.showCol = json.showCol
      //     this.boldCol = json.boldCol
      //     this.colorCol = json.colorCol
      //     this.color = json.color
      //   }

      //   this.gethtml(res.data.htmlRef);
      //   this.getHtmlBill(res.data.htmlBill)

      //   this.getCsvPage(id, 1, 10)

      //   // this.$api.getcppbtoolresult(this.id + '/report').then((res) => {
      //   //   this.drtailReport = res.data
      //   //   this.showCVS()
      //   // });
      // });
    },
    // 初始化变量
    init(){
      for(let item of this.billList){
        let id = this.keyHandler(item.id)
        this.drtail[id] = {}
        this.htmltitle[id] = ""
        this.htmlBillContent[id] = ""
        this.pages[id] = {
          current: 1,
          size: 10,
          total: 0
        }
        this.dowloadBtn[id] = false
        this.checkAll[id] = []
        this.isIndeterminate[id] = false
        this.checkedOption[id] = []
        this.tableOption[id] = []
        this.allTableData[id] = []
        this.tableData[id] = []
        this.showCol[id] = []
        this.boldCol[id] = []
        this.colorCol[id] = []
        this.color[id] = ''
        this.colWidth[id] = []
      }  
    },
    keyHandler(id){
      return id + "_id"
    },
    unKeyHandler(key){
      return key.split('_')[0]
    },
    // 分页获取csv数据列表
    getCsvPage(id, current, size, orders, asc){
      let key = this.keyHandler(id)
      this.tableData[key] = [];
      this.allTableData[key] = [];

      let params = id + '/page?current=' + current + '&size=' + size;
      if(orders){
        if(asc){
          params += '&ascs=' + orders
        }else{
          params += '&descs=' + orders
        }
      }
      this.$api.getcppbtoolresult(params).then((res) => {
        for(let item of res.data.records){
          let kt = Object.keys(item)[Object.keys(item).length - 1]
          if(item[kt].indexOf('/admin/sys-file') > -1){
            item[kt] = window.location.protocol + '//' + window.location.host + item[kt]
          }
        }
        this.allTableData[key] = res.data.records;
        this.tableData[key] = res.data.records;
        let titles = Object.keys(res.data.records[0]);
        this.tableOption[key] = titles
        this.checkedOption[key] = this.checkedOption[key] && this.checkedOption[key].length > 0 ? this.checkedOption[key] : (this.showCol[key] ? this.showCol[key] : titles);
        this.colWidthHandler(key)
        this.checkLastColIsFile(key)

        this.pages[key].total = res.data.total
        this.$forceUpdate()
        this.fiexdColStyle()
      });
    },
    // 每页数
    sizeChangeHandle (val, id) {
      this.pages[id].size = val
      this.pages[id].current = 1
      this.getCsvPage(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size)
    },
    // 当前页
    currentChangeHandle (val, id) {
      this.pages[id].current = val
      this.getCsvPage(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size)
    },
    // 表格排序触发
    tableSortChange({ prop, order }, id){
      this.pages[id].current = 1
      if(order == null){
        this.getCsvPage(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size)
      }else{
        let b = order == 'ascending' ? true : false
        this.getCsvPage(this.unKeyHandler(id), this.pages[id].current, this.pages[id].size, prop, b)
      }
    },
    // 获取富文本信息 结果描述
    gethtml(id, pid) {
      this.$api.getWorkHtml(id).then((res) => {
        this.htmltitle[pid] = res.data.content;
      });
    },
    // 榜单描述  富文本
    getHtmlBill(id, pid){
      this.$api.getWorkHtml(id).then((res) => {
        this.htmlBillContent[pid] = res.data.content;
      });
      
    },
    // 获取当前数据
    getthidlist() {
      this.$api.getSiugnupList({}).then((res) => {
        res.data.forEach((item) => {
          if (this.id == item.standardId) {
            this.billist = item;
          }
        });
      });
    },
    // 获取当前数据
    getlist() {
      this.$api.postdetailData({ specId: this.id }).then((res) => {
        var obj1 = {
          id: res.data.spec.id,
          name: res.data.spec.title,
        };
        var obj2 = {
          id: res.data.standard.id,
          name: res.data.standard.name,
        };
        var obj3 = {
          id: res.data.tool.id,
          name: res.data.tool.name,
        };
        this.detaillist.push(obj1, obj2, obj3);
      });
    },

    // 字符串长度截取
    lengthSub(txt){
      return txt
      // return txt == null || txt == '' || txt.length < 30 ? txt : txt.substr(0, 30) + '...';
    },
    // 列宽度
    getColWidth(c) {
      if(this.col60.indexOf(c) > -1){
        return '60px';
      }
      else if(this.col120.indexOf(c) > -1){
        return '120px';
      }
      else if(this.col180.indexOf(c) > -1){
        return '180px';
      }
      else if(this.col220.indexOf(c) > -1){
        return '230px';
      }
      else if(this.col260.indexOf(c) > -1){
        return '270px';
      }
      else{
        return '120px';
      }
    },
    colWidthHandler(id){
      this.colWidth[id] = []
      for(let k of this.checkedOption[id]){
        let w = this.getColWidth(k)
        this.colWidth[id].push(w)
      }
    },
    // 检查最后一列是不是文件下载
    checkLastColIsFile(id){
      this.dowloadBtn[id] = false
      for(let item of this.tableData[id]){
          let key = this.checkedOption[id][this.checkedOption[id].length - 1]
          if(item[key].indexOf('/admin/sys-file') > -1){
            this.dowloadBtn[id] = true
            
          }
      }
      this.$forceUpdate()
    },
    // 固定下载列 样式问题修复
    fiexdColStyle(){
      this.$nextTick(() => {
        setTimeout(function(){
          // 固定下载列 样式问题修复
          let doms = document.getElementsByClassName("el-table__fixed-body-wrapper");
          if(doms != null && doms.length > 0){
            for(let dom of doms){
              dom.style.top = '47px'
            }
            // dom.setAttribute('style', "top: 38px;")
          }
        }, 60)
      })
      
    },

    downloadFile(url){
      window.open(url, '_blank')
      // let a = document.createElement("a");
      // a.href = url;
      // a.target = '_blank';
      // a.click();
    },

    // 锚点定位
    jumpHandler(id){
      let kk = 'bill_detail_' + this.keyHandler(id)
      document.querySelector('#'+kk).scrollIntoView({
        'behavior' : 'smooth'
      })
    },
    // 回到顶部
    returnTop(){
      
      document.querySelector('#app').scrollIntoView({
        'behavior' : 'smooth'
      })
      // window.scrollBy(0,-100);//Only for y vertical-axis
      // console.log(',,', document.body, document.body.scrollTop)
      // if(document.body.scrollTop > 0) { 
      //   let thiz = this
      //   setTimeout(function(){
      //     thiz.returnTop()
      //   },50);
      // }
    },
    

    // 全选
    handleCheckAllChange(val, id) {
        this.checkedOption[id] = val ? this.tableOption[id] : [];
        this.colWidthHandler(id)
        this.tableData[id] = val ? this.allTableData[id] : [];
        this.isIndeterminate[id] = false;
        this.checkLastColIsFile(id)
        this.fiexdColStyle()
    },
    // 单选
    handleCheckedTableChange(val, id) {
        // let arr = [];
        // for (let i = 0; i < this.tableOption[id].length; i++) {
        //     if (val.indexOf(this.tableOption[id][i]) != -1) {
        //         arr.push(this.tableOption[id][i]);
        //     }
        // }
        this.tableData[id] = val.length == 0 ? [] : this.allTableData[id];
        // this.checkedOption[id] = arr;
        this.colWidthHandler(id)
        let checkedCount = val.length;
        this.checkAll[id] = checkedCount === this.tableOption[id].length;
        this.isIndeterminate[id] = checkedCount > 0 && checkedCount < this.tableOption[id].length;
        this.checkLastColIsFile(id)
        this.fiexdColStyle()
    },
    
    // // 已经不用了
    // showCVS(){
    //       if(!this.drtail.resUrl || !this.drtail.resName){
    //         return
    //       }
    //       let fileurl = window.location.protocol + '//' + window.location.host + "/admin/sys-file/" + this.drtail.resUrl + "/" + this.drtail.resName;
    //       this.getFileByUrl(fileurl, this.drtail.resName).then((res) => {
    //           let file = res
    //           if(file.size > 0){
    //             this.handleChange(file)
    //           }
    //       })
    //       .catch((e) => {
    //           console.log(e)
    //       })
    // },
    // // 解析  不用了
    // CSVToArray(strData, strDelimiter) {
    //   // Check to see if the delimiter is defined. If not,
    //   // then default to comma.
    //   strDelimiter = strDelimiter || ",";

    //   // Create a regular expression to parse the CSV values.
    //   var objPattern = new RegExp(
    //       // Delimiters.
    //       "(\\" +
    //       strDelimiter +
    //       "|\\r?\\n|\\r|^)" +
    //       // Quoted fields.
    //       '(?:"([^"]*(?:""[^"]*)*)"|' +
    //       // Standard fields.
    //       '([^"\\' +
    //       strDelimiter +
    //       "\\r\\n]*))",
    //       "gi"
    //   );

    //   // Create an array to hold our data. Give the array
    //   // a default empty first row.
    //   var arrData = [[]];

    //   // Create an array to hold our individual pattern
    //   // matching groups.
    //   var arrMatches = null;

    //   // Keep looping over the regular expression matches
    //   // until we can no longer find a match.
    //   while ((arrMatches = objPattern.exec(strData))) {
    //       // Get the delimiter that was found.
    //       var strMatchedDelimiter = arrMatches[1];

    //       // Check to see if the given delimiter has a length
    //       // (is not the start of string) and if it matches
    //       // field delimiter. If id does not, then we know
    //       // that this delimiter is a row delimiter.
    //       if (
    //       strMatchedDelimiter.length &&
    //       strMatchedDelimiter !== strDelimiter
    //       ) {
    //       // Since we have reached a new row of data,
    //       // add an empty row to our data array.
    //       arrData.push([]);
    //       }

    //       var strMatchedValue;

    //       // Now that we have our delimiter out of the way,
    //       // let's check to see which kind of value we
    //       // captured (quoted or unquoted).
    //       if (arrMatches[2]) {
    //       // We found a quoted value. When we capture
    //       // this value, unescape any double quotes.
    //       strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    //       } else {
    //       // We found a non-quoted value.
    //       strMatchedValue = arrMatches[3];
    //       }

    //       // Now that we have our value string, let's add
    //       // it to the data array.
    //       arrData[arrData.length - 1].push(strMatchedValue);
    //   }

    //   // Return the parsed data.
    //   return arrData;
    // },
    // // 上传 不用了
    // handleChange(file) {
    //   /**
    //    * 方法一：将文件上传到后台，后台解析并返回数据，由前台显示
    //    */
    //   // uploadFile(file.raw).then((res) => {
    //   //   console.log("获取后台返回的数据",res);
    //   // });
    //   /**
    //    * 方法二：前台解析文件并显示
    //    */
    //   this.tableData = [];
    //   this.allTableData = [];
    //   const reader = new FileReader();
    //   reader.readAsText(file, "gbk");
    //   reader.onload = (e) => {
    //       let csvContent = e.target.result;
    //       let items = this.CSVToArray(csvContent);
    //       let arr = [];
    //       for (let i = 0; i < items.length; i++) {
    //         if (i != 0 && JSON.stringify(items[i]) !== JSON.stringify([""])) {
    //             let obj = {};
    //             for (let j = 0; j < items[i].length; j++) {
    //               let m = items[0][j];
    //               let n = items[i][j];
    //               if(j == items[i].length - 1){
    //                 for(let report of this.drtailReport){
    //                   if(report.originalName == n){
    //                     n = window.location.protocol + '//' + window.location.host + "/admin/sys-file/" + report.reportUrl + "/" + report.reportName;
    //                   }
    //                 }
    //               }
    //               obj[m] = n;
    //             }
    //             arr.push(obj);
    //         }
    //       }
    //       this.allTableData = arr;
    //       this.tableData = arr;
    //       this.tableOption = items[0];
    //       this.checkedOption = this.showCol ? this.showCol : items[0];
    //       this.colWidthHandler()
    //       this.checkLastColIsFile()
    //   };
    // },
    // // 不用了
    // getFileByUrl(url, fileName){
    //     return new Promise((resolve, reject) => {
    //         var blob = null;
    //         var xhr = new XMLHttpRequest();
    //         xhr.open('get', url);
    //         xhr.setRequestHeader('Content-Type', "application/octet-stream; charset=UTF-8");
    //         xhr.responseType = 'blob'
    //         xhr.onload = () => {
    //             blob = xhr.response;
    //             let file = new File([blob], fileName);
    //             resolve(file);
    //         };
    //         xhr.onerror = (e) => {
    //             reject(e)
    //         };
    //         xhr.send();
    //     })
    // },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1024px) {
  .centext {
    margin: 1.25rem 0;
    padding: 0 1.25rem;
    
    .title {
      text-align: left;
      // padding: 0 12rem;
      -webkit-line-clamp: 2;
      font-size: 1.375rem;
      font-weight: 300;
      line-height: 2.5rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      font-family: '宋体', 'Courier New', Courier, monospace;
      // display: flex;

      .time{
        flex: 1;
        text-align: right;
        font-weight: 400;
        font-size: 1.25rem;
      }
    }
    .text {
      text-align: left;
      padding: 0;
    }
    .summary {
      font-size: 1.0rem;
      // text-indent: 2rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
      font-weight: 300;
    }
    .bord {
      text-indent: 0;
    }
    /deep/ p {
      font-size: 1rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
      font-weight: 300;
    }
    .mode {
      justify-content: flex-start;
      margin: 1rem 0;
      div {
        font-size: 1.125rem;
      }
      .linkpath {
        font-size: 1rem;
        color: #1b289e;
        margin-right: 0.625rem;
      }
      .linkpath:hover {
        cursor: pointer;
        color: #5a71f0;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
        font-size: 1.125rem;
      }
    }

    .billDesc{
      height: 4rem;
      background: #F2F6FC;
      border-radius: 0.2rem;
      margin-bottom: 0.4rem;
    }
    .billListBox{
      background: #F2F6FC;
      padding: 0.4rem;
      line-height: 1.8rem;
    }
    .billListTitle{
      margin-right: 0.2rem;
      color: #606266;
      font-size: 1rem;
      cursor:pointer;
      padding: 0.3rem;
      border-radius: 0.2rem;
    }
    .active {
      color: #409EFF;
    }
    .billListTitle:hover{
      // text-decoration:underline;
      // color: #409EFF;
      background: #E4E7ED;
    }
  }

  .backtop{
    position: fixed;
    background-color: #EBEEF5;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    cursor: pointer;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  /deep/ .el-breadcrumb {
    display: none;
  }
  .centext {
    margin: 0.625rem;
    .title {
      text-align: left;
      -webkit-line-clamp: 2;
      font-size: 1rem;
      font-weight: 300;
      line-height: 2rem;
      margin-bottom: 0.625rem;
      margin-top: 1rem;
      font-family: '宋体', 'Courier New', Courier, monospace;
      
    }
    .text {
      text-align: left;
      padding: 0;
    }
    .summary {
      font-size: 1rem;
      // text-indent: 2rem;
      line-height: 1.8rem;
      margin-bottom: 0.325rem;
      font-weight: 300;
    }
    .bord {
      text-indent: 0;
    }
    /deep/ p {
      font-size: 1rem;
      line-height: 1.8rem;
      margin-bottom: 0.625rem;
      font-weight: 300;
    }
    .mode {
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 1rem 0;
      div {
        font-size: 1rem;
      }
      .linkpath {
        font-size: 1rem;
        color: #1b289e;
        margin-right: 0.625rem;
      }
      .linkpath:hover {
        cursor: pointer;
        color: #5a71f0;
      }
      img {
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 1rem;
        font-size: 1rem;
      }
    }

    .billDesc{
      height: 4rem;
      background: #F2F6FC;
      border-radius: 0.2rem;
      margin-bottom: 0.4rem;
    }
    .billListBox{
      background: #F2F6FC;
      padding: 0.4rem;
      line-height: 1.8rem;
    }
    .billListTitle{
      margin-right: 0.2rem;
      color: #606266;
      font-size: 1rem;
      cursor:pointer;
      padding: 0.3rem;
      border-radius: 0.2rem;
    }
    .active {
      color: #409EFF;
    }
    .billListTitle:hover{
      // text-decoration:underline;
      // color: #409EFF;
      background: #E4E7ED;
    }
  }

  .backtop{
    position: fixed;
    background-color: #EBEEF5;
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    cursor: pointer;
    z-index: 5;
  }
}

</style>
<style scoped lang="less"> 
/** 复选框样式 */
  .billdetail-box .el-checkbox-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  .billdetail-box .el-checkbox {
      margin: 0.2rem 0;
      line-height: 1.6;
  }
  .billdetail-box .el-checkbox__label{
    font-size: .9rem;
    padding-left: 0.23333rem;
    line-height: 0.9rem;
  }
  .billdetail-box .el-checkbox__inner{
      width: .8rem;
      height: .8rem;
      border-radius: .2rem;
  }
  .billdetail-box .el-checkbox__inner::after{
    height: 0.25rem;
    width: 0.25rem;
    left: 0.2rem;
    top: 0.14rem;
  }
  .billdetail-box .el-checkbox__input.is-indeterminate .el-checkbox__inner::before{
    top: 0.3rem;
  }

  /** 表格样式 */
  .billdetail-box .el-table {
    font-size: 0.9rem;
    margin-bottom: 0.6rem;
  }
  .billdetail-box .el-table .cell,
  .el-table th > .cell {
      display: inline-block;
      overflow: hidden;
      line-height: 2.6rem;
  }
  /* .billdetail-box .el-table td > .cell{
    line-height: 1.2rem;
  } */
  .billdetail-box .el-table__empty-text{
    line-height: 2rem;
  }

  .billdetail-box .el-table .caret-wrapper{
    height: 1rem;
    width: 1rem;
  }
  /**表格标题排序箭头 */
  .billdetail-box .el-table .sort-caret{
    border: 0.32rem solid transparent;
    left: 0.36rem;
  }
  .billdetail-box .el-table .sort-caret.ascending{
    top: -0.24rem;
    border-bottom-color: #C0C4CC;
  }
  .billdetail-box .el-table .ascending .sort-caret.ascending{
    border-bottom-color: #409EFF;
  }
  .billdetail-box .el-table .sort-caret.descending{
    bottom: -0.20rem;
    border-top-color: #C0C4CC;
  }
  .billdetail-box .el-table .descending .sort-caret.descending{
    border-top-color: #409EFF;
  }
 
  /** 分页样式 */
  .billdetail-box .el-pagination{
    text-align: right;
  }
  .billdetail-box .el-pagination button, .el-pagination span:not([class*=suffix]){
    font-size: 0.9rem;
    min-width: 1rem;
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .billdetail-box .el-pagination .el-select .el-input{
    width: 6rem;
    margin: 0 0.4rem;
  }
  .billdetail-box .el-pagination__sizes .el-input .el-input__inner{
    font-size: 0.9rem;
    border-radius: 0.2rem;
    margin-top: -0.3rem;
  }
  .billdetail-box .el-input--mini .el-input__inner{
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .billdetail-box .el-pager li {
    padding: 0 0.5rem;
    font-size: 0.8rem;
    min-width: 0.47333rem;
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .billdetail-box .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    margin: 0 0.3rem;
    min-width: 1.8rem;
    border-radius: 0.2rem;
  }
  .billdetail-box .el-pagination__editor.el-input{
    width: 2rem;
  }
  .billdetail-box .el-input{
    font-size: 0.9rem;
  }
  .billdetail-box .el-pagination__editor{
    line-height: 1.6rem;
    height: 1.6rem;
    border-radius: 0.2rem;
    margin: 0 0.4rem;
  }
  .billdetail-box .el-pagination__editor.el-input .el-input__inner {
    height: 1.6rem;
    border-radius: 0.2rem;
  }
  .el-select-dropdown__item {
    font-size: 0.8rem;
    padding: 0 0.26667rem;
    height: 1.6rem;
    line-height: 1.6rem;
  }
  .el-pagination .el-input__suffix{
    right: 0.3rem;
    margin-top: -0.1rem;
  }
  .el-select-dropdown__wrap {
      max-height: 8rem;
  }
  .el-select .el-input .el-select__caret{
    font-size: 0.8rem;
  }
  .el-input__icon{
    width: 0.8rem;
  }
</style>