<template>
    <div>
        <div class="topheader">
            <!-- 引入的头部组件（传入页面数据 -->
            <headerbox
                    :list="list"
                    @changenar="changenar"
                    :pagenum="primary"
                    :chinum="secondary"
            />
        </div>

        <div class="contentIn">
            <div class="user findIn">
                <div class="title">
                    <div class="titleW">单位信息及密码修改</div>
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="登录名" prop="username">
                        <el-input v-model="ruleForm.username" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="负责人" prop="header">
                        <el-input v-model.trim="ruleForm.header" maxlength="10"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="linkman">
                        <el-input v-model.trim="ruleForm.linkman" maxlength="10"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人电话" prop="linktele">
                        <el-input v-model.number="ruleForm.linktele" maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人邮箱" prop="email">
                        <el-input v-model.trim="ruleForm.email" maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="单位名称" prop="name">
                        <el-input v-model.trim="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="单位性质" prop="nature">
                        <el-input v-model.trim="ruleForm.nature"></el-input>
                    </el-form-item>
                    <el-form-item label="单位地址" prop="address">
                        <el-input v-model.trim="ruleForm.address"></el-input>
                    </el-form-item>
                    <el-form-item label="原密码" prop="pwd">
                        <el-input type="password"  v-model.trim="ruleForm.pwd" maxlength="32"></el-input>
                    </el-form-item>
                    <el-form-item label="输入新密码" prop="pwd1">
                        <el-input type="password" v-model.trim="ruleForm.pwd1" maxlength="32"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="pwd2">
                        <el-input type="password" v-model.trim="ruleForm.pwd2" maxlength="32"></el-input>
                    </el-form-item>
                    <el-form-item class="btn-box">
                        <el-button class="btn-sure" @click="resetFormJump()">取消</el-button>
                        <el-button type="primary" class="btn-sure" @click="submitForm('ruleForm')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    // //  引入的头部组件
    import headerbox from "@/components/header/headerbox";
    // //  引入的尾部组件
    // import footerbox from "@/components/footer/footerbox";
    // //  引入的导航组件
    // import leftab from "@/components/all/leftab";
    // //  引入的页面组件
    // import workgroup from "../../components/page/work/workgroup";
    export default {
        data() {
            let reg = /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*<)(?!.*>).{8,32}$/;

            var validatePass = (rule, value, callback) => {
                    if (value === '') {
                        callback(new Error('请输入密码'));
                    } else {
                        // console.log(reg.test(this.pwd1))
                        if(!reg.test(value)){
                            callback(new Error('密码格式为由大写字母、小写字母、数字、特殊字符(除<>)四种组成长度为8-32的字符串'));
                            return;
                        }
                        // if (this.ruleForm.pwd2 !== '') {
                        //     this.$refs.ruleForm.validateField('pwd2');
                        // }
                        // console.log('-------------');
                        // callback();
                       callback();
                    }
            };
            var validatePass2 = (rule, value, callback) => {
                    if (value === '') {
                        callback(new Error('请再次输入密码'));
                    } else if(!reg.test(value)){
                        callback(new Error('密码格式为由大写字母、小写字母、数字、特殊字符(除<>)四种组成长度为8-32的字符串'));
                        return;
                    }else if (value !== this.ruleForm.pwd1) {
                        callback(new Error('两次输入密码不一致!'));
                    } else {
                         callback();
                    }
            };
            return {
                password:'',
                list: [], //导航栏数组
                primary: 1, //一级菜单的选中样式
                secondary: 0, //二级菜单的选中样式
                istrue: true, //刷新导航栏数据
                socllist: [0, 300],
                narlist:[],
                ruleForm: {
                    header: '',
                    linkman: '',
                    linktele: '',
                    email: '',
                    pwd: '',
                    pwd1: '',
                    pwd2: '',
                    username: '',
                    address: '',
                    id:'',
                    status:1,
                    name:'',
                    nature:''
                },
                rules: {
                    header: [
                        { required: true, message: '请输入负责人', trigger: 'blur' }
                    ],
                    linkman: [
                        { required: true, message: '请输入联系人', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入单位名称', trigger: 'blur' }
                    ],
                    nature: [
                        { required: true, message: '请输入单位性质', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请输入单位地址', trigger: 'blur' }
                    ],
                    linktele: [
                        { required: true, message: '请输入联系人电话', trigger: 'blur' },
                        // { type: 'number', message: '必须为数字值', trigger: 'blur' }
                    ],
                    email: [
                        { required: true, message: '请输入邮箱', trigger: 'change' },
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ],
                    pwd: [
                        {required: true, message: '请输入密码', trigger: 'change' }
                    ],
                    pwd1: [
                        {required: true, validator: validatePass, trigger: 'change'}
                    ],
                    pwd2: [
                        {required: true, validator: validatePass2, trigger: 'change'}
                    ]
                },
                info:''
            };
        },
        created() {
            this.secondary = this.$route.query.chinum;
            this.info = this.until.cacheSync("get", "pigx-userInfo");
        },
        mounted() {
            // 进入页面加载导航栏数据
            this.getList1();
            // console.log(document.getElementById('cnzz_stat_icon_1280450777'));
            document.getElementById('cnzz_stat_icon_1280450777').style.display = 'none';
        },
        components: {
            headerbox, //引入的头部组件
            //     footerbox, //引入的尾部组件
            //     workgroup, //引入的页面组件
            //     leftab, //引入的导航组件
        },
        methods: {
            getList1(){
                setTimeout(() => {
                    this.$api.getUserInfo({
                        "id":this.until.cacheSync("get", 'getUserNew').id,//账号
                    }).then((res) => {
                        // console.log(res);
                        if(res.data){
                            this.ruleForm.header = res.data.header;
                            // this.ruleForm.pwd = res.data.pwd;
                            this.ruleForm.linkman = res.data.linkman;
                            this.ruleForm.email = res.data.email;
                            this.ruleForm.linktele = res.data.linktele;
                            this.ruleForm.address = res.data.address;
                            this.ruleForm.username = res.data.username;
                            this.ruleForm.name = res.data.name;
                            this.ruleForm.nature = res.data.nature;
                            this.ruleForm.id = res.data.id;
                        }
                    });
                }, 100);
            },
            submitForm() {
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        setTimeout(() => {
                            this.$api.getUpdateById(this.ruleForm).then((res) => {
                                if(res.code == '1'){
                                    this.$message.error(res.msg);
                                    return
                                }
                                this.$message.success('信息修改成功！');
                                setTimeout(()=>{
                                    window.sessionStorage.clear()
                                    window.localStorage.clear()
                                    // 点击登录跳转到后台的登录页面
                                    this.until.jump.call(this, "/uiadmin", "href");
                                },1000)
                            }).catch((res) => {
                                // console.log(res)
                                this.$message.error(res.msg);
                            });
                        }, 100);
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            resetFormJump(){
                this.until.jump.call(this, "/home", "push");
            },
            // 刷新当前页面
            refresh() {
                this.istrue = false;
                this.$nextTick(() => {
                    this.istrue = true;
                });
            },
            // 点击导航传出当前选中菜单
            changenar(e) {
                this.primary = e.pagenum; //一级菜单的选中样式
                if (this.primary != 1||this.secondary != e.chinum) {
                    this.secondary = e.chinum; //二级菜单的选中样式
                    this.until.jump.call(this, this.list[this.primary].path, "push", {
                        pagenum: this.primary,
                        chinum: this.secondary,
                    });
                    this.refresh();
                }
            },
        },
    };
</script>
<style scoped lang="less">
    .user{
        margin: 50px auto 0;
        background: #fff;
        padding: 30px;
        width: 1000px;
        box-shadow: 2px 2px 8px 2px #ededed;
        max-width: 100%;
        overflow: hidden;
    }
    .findIn{
        width: 50rem;
        height: 50rem;
        margin: 0 auto 2rem;
        border: 0.01333rem #d2d2d2 solid;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        overflow: hidden;
        box-shadow: 0 0 0 0;
        margin-top: 2rem;
    }
    .title {
        background: none;
        text-align: center;
        padding-left: 20px;
        line-height: 30px;
        margin-bottom: 30px;
    }
    .titleW {
        font-size: 2.5rem;
        border-bottom: 0.1rem #dcdcdc solid;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        margin-bottom: 1rem;
        line-height: 3rem;
        color: #00b1e4;
    }
    .btn-sure{
        width: 5rem;
        height: 2rem;
    }
    @media screen and (max-width: 1023px) {
        .findIn[data-v-53015f05] {
            width: 22rem;
        }
    }
</style>
<style>
    html{
        background: #f6f6f6;
    }
    .demo-ruleForm{
        height: 30rem;
        width: 75%;
        margin: 0 auto;
    }
    .demo-ruleForm .el-form-item__label {
        font-size: 1rem;
        line-height: 2rem;
        padding: 0 0.16rem 0 0;
    }
    .demo-ruleForm .el-form-item {
        margin-bottom: 1.5rem;
        height: 2rem;
    }
    .demo-ruleForm .el-form-item__content {
        line-height: 2rem;
        position: relative;
        font-size: 1rem;
    }
    .demo-ruleForm .el-input__inner {
        height: 2rem;
        line-height: 2rem;
    }
    .demo-ruleForm .el-form-item__content{
        text-align: right;
    }
    @media screen and (max-width: 1023px) {
        .btn-box .el-form-item__content {
            margin: 0 !important;
        }
    }

</style>

