<template>
  <!-- 这是面包屑 -->
  <div class="box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item, index) in list" :key="index">
        <span
          :class="{ back: index != list.length - 1 }"
          @click="tolink(index)"
          >{{ item }}</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "crumbs",
  props: {
    list: {
      typeof: [Array],
    },
    // 当前的页码(1级菜单)
    pagenum: {
      typeof: [Number],
      default: 0,
    },  
    // 当前的页码(2级菜单)
    chinum: {
      typeof: [Number],
      default: 1,
    },
  },
  methods: {
    // 点击进行跳转
    tolink(index) {
      var list = this.until.cacheSync("get", "navigation").data;
 
      // 点击第一个进行跳转
      if (index == 0 || index == 1) {
        this.until.jump.call(this, list[this.pagenum].path, "push", {
          pagenum: this.pagenum,
          chinum: this.chinum,
        });
      } else if (index == 2) {
        if (this.list.length == 4) {
          //   返回上一级
          this.until.jump.call(this, -1, "go");
        } else if (this.list.length == 5) {
          this.until.jump.call(this, -2, "go");
        }
      } else if (index == 3) {
        if (this.list.length == 5) {
          //   返回上一级
          this.until.jump.call(this, -1, "go");
        }
      }
    },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1024px) {
  // 当前页面
  .box {
    height: 3.75rem;
    border-bottom: 0.0625rem solid #ececec;
    .back:hover {
      color: #a0baeb;
      cursor: pointer;
    }
    // 面包屑
    /deep/.el-breadcrumb {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 1.25rem;
      // 文字内容
      .el-breadcrumb__item {
        font-size: 1.125rem;
        // 分割符号
        .el-breadcrumb__separator {
          margin: 0 0.225rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
}
</style>
